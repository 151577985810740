import {getTimeStamp, getCookieData, getPlatformName} from './Utils';
import {SpApiResponse, AdDetails} from '../configs/SpApiResponse';
import {ZeePlayerConfig} from '../configs/ZeePlayerConfig';

// Extracting the ad intervals
export function checkAdsAvailability(singlePlayDetails: SpApiResponse) {
  const {adDetails = []} = singlePlayDetails;
  const {video_ads = []} = adDetails as AdDetails;
  if (video_ads.length > 0) {
    const {ads_visibility = false, intervals = [], adsUrl = ''} = video_ads[0];
    if (ads_visibility && intervals && intervals.length) {
      return vmapGenerator(intervals);
    }
    if (ads_visibility && adsUrl) {
      return adsUrl;
    }
  }
}

// Code to check if adsUrl is present with video ads
export function checkIfAdsUrlPresent(singlePlayDetails: SpApiResponse) {
  // const {adDetails} = singlePlayDetails;
  const {video_ads = ''} = singlePlayDetails && singlePlayDetails.adDetails;
  const {adsUrl = ''} = (video_ads &&
    video_ads?.length > 0 &&
    video_ads[0]) || {
    adsUrl: ''
  };
  return adsUrl ? true : false;
}

// combining pre, mid, post roll ads with vmap
export function vmapGenerator(vmapPayload: any[]) {
  let ads, ad_Time, ad_Url, ad_Tag, imaTag, imagTagPost, imagTagMid;
  const imagTagMidArray: string[] = [];
  const imaTagPreArray: string[] = [];
  const imagTagPostArray: string[] = [];
  // resetting the ads variable on every playback
  ads = null;
  const adsHeader = `<vmap:VMAP xmlns:vmap="http://www.iab.net/videosuite/vmap" version="1.0">`;
  const adsFooter = `</vmap:VMAP>`;
  vmapPayload.map((ad_time, index) => {
    ad_Time = ad_time.time;
    ad_Url = ad_time.tag;
    ad_Tag = ad_time.tag_name;
    ad_Time = Number(ad_Time);
    if (isNaN(ad_Time)) {
      ad_Time = ad_time.time;
    } else {
      const h = Math.floor(ad_Time / 3600);
      const m = Math.floor((ad_Time % 3600) / 60);
      const s = Math.floor((ad_Time % 3600) % 60);
      const hDisplay = h > 9 ? h : `0${h}`;
      const mDisplay = m > 9 ? m : `0${m}`;
      const sDisplay = s > 9 ? `${s}.000` : `0${s}.000`;
      ad_Time = hDisplay + ':' + mDisplay + ':' + sDisplay;
    }
    switch (ad_Time) {
      case 'pre':
        imaTag = `<vmap:AdBreak timeOffset="start" breakType="linear" breakId="preroll">
                    <vmap:AdSource id="${ad_Tag}" allowMultipleAds="false" followRedirects="true">
                    <vmap:AdTagURI templateType="vast3">
                    <![CDATA[${ad_Url + getTimeStamp()}]]>
                    </vmap:AdTagURI>
                    </vmap:AdSource>
                    </vmap:AdBreak>`;
        imaTagPreArray.push(imaTag);
        break;
      case 'post':
        imagTagPost = `<vmap:AdBreak timeOffset="end" breakType="linear" breakId="postroll">
                    <vmap:AdSource id="${ad_Tag}" allowMultipleAds="false" followRedirects="true">
                    <vmap:AdTagURI templateType="vast3">
                    <![CDATA[${ad_Url + getTimeStamp()}]]>
                    </vmap:AdTagURI>
                    </vmap:AdSource>
                    </vmap:AdBreak>`;
        imagTagPostArray.push(imagTagPost);
        break;
      default:
        imagTagMid = `<vmap:AdBreak timeOffset="${ad_Time}" breakType="linear" breakId="midroll-${index}">
                    <vmap:AdSource id="${ad_Tag}" allowMultipleAds="false" followRedirects="true">
                    <vmap:AdTagURI templateType="vast3">
                    <![CDATA[${ad_Url + getTimeStamp()}]]>
                    </vmap:AdTagURI>
                    </vmap:AdSource>
                    </vmap:AdBreak>`;
        imagTagMidArray.push(imagTagMid);
    }
  });

  const preAds = imaTagPreArray.join('');
  const midAds = imagTagMidArray.join('');
  const postAds = imagTagPostArray.join('');
  imaTagPreArray.length > 0 &&
  imagTagMidArray.length > 0 &&
  imagTagPostArray.length > 0
    ? (ads = adsHeader + preAds + midAds + postAds + adsFooter)
    : imaTagPreArray.length > 0 && imagTagMidArray.length > 0
    ? (ads = adsHeader + preAds + midAds + adsFooter)
    : imaTagPreArray.length > 0 && imagTagPostArray.length > 0
    ? (ads = adsHeader + preAds + postAds + adsFooter)
    : imagTagMidArray.length > 0 && imagTagPostArray.length > 0
    ? (ads = adsHeader + midAds + postAds + adsFooter)
    : imaTagPreArray.length > 0
    ? (ads = adsHeader + preAds + adsFooter)
    : imagTagPostArray.length > 0
    ? (ads = adsHeader + postAds + adsFooter)
    : (ads = adsHeader + midAds + adsFooter);
  return ads;
}

// Fetching customs Parameters from player configuration
export const getDAICustomParameters = (
  mixpanelData: any,
  playerConfig: ZeePlayerConfig,
  singlePlayDetails: SpApiResponse
) => {
  let params: any = {
    ppid: playerConfig.guestToken,
    vid: singlePlayDetails.assetDetails.id,
    cust_params: {
      platform_name: getPlatformName(),
      app_version: mixpanelData.appVersion,
      user_display_language: playerConfig.displayLanguage,
      user_content_language: getCookieData('content-language'),
      user_type: playerConfig.userType,
      zid: playerConfig.guestToken
    }
  };
  if (playerConfig.userType !== 'guest') {
    params = {
      ...params,
      cust_params: Object.assign(
        params.cust_params,
        {age: playerConfig.userData.age},
        {gender: playerConfig.userData.user.gender},
        {uid: playerConfig.userData.user.id}
      )
    };
  }
  if (
    playerConfig.isSportsLiveEvent({
      assetType: singlePlayDetails.assetDetails.asset_type,
      assetSubType: singlePlayDetails.assetDetails.asset_subtype
    })
  ) {
    params = {
      ...params,
      cust_params: Object.assign(params.cust_params, {
        audio_language: singlePlayDetails.keyOsDetails.stream_language
      })
    };
  }
  return params;
};
