import {playerContainerFocused} from '../utils/playerEventsHelper';
import videojs from 'video.js';
// import './SeekButton.css';

export function addSeekButtons(playerRef: any) {
  // seekControlsLoadedet  = true;
  const player = playerRef; // Player instance

  /* Hiding default play button */
  player.removeChild('PlayToggle');
  player.removeChild('PictureInPictureToggle');
  player.removeChild('BigPlayButton');

  const Button = videojs.getComponent('Button');
  const seekTime = (timeSec: number): object => {
    return {
      clickHandler: () => {
        seek(timeSec, playerRef);
      }
    };
  };
  const skipForButton = new Button(player, seekTime(10));
  const skipBackButton = new Button(player, seekTime(-10));
  skipForButton.addClass('vjs-skip-forward');
  skipForButton.controlText('Forward 10s');
  skipBackButton.addClass('vjs-skip-backward');
  skipBackButton.controlText('Backward 10s');

  const Component = videojs.getComponent('Component');

  /* Creating new wrapper component */
  const myWrapperComp = new Component(player);

  /* Adding wrapper component to player */
  player.addChild(myWrapperComp).addClass('vjs-skip-wrapper');

  /* Adding child component to wrapper component */
  myWrapperComp.addChild(skipBackButton);
  myWrapperComp.addChild('PlayToggle').addClass('vjs-playing');
  myWrapperComp.addChild(skipForButton);
}

export function seek(
  secs: number,
  videoPlayer: {hotkeys?: (event: any) => void; currentTime?: any}
) {
  if (videoPlayer) {
    window.autoSeek = true;
    let time = videoPlayer.currentTime() + secs;
    if (time < 0) time = 0;
    videoPlayer.currentTime(time);
    playerContainerFocused();
  }
}
