export class AdEvents {
  static readonly AD_CLICKED: string = 'ad_clicked';
  static readonly AD_ENDED: string = 'ad_ended';
  static readonly AD_ERROR: string = 'ad_error';
  static readonly AD_LOADED: string = 'ad_loaded';
  static readonly AD_MANAGER_LOADED: string = 'ad_manager_loaded';
  static readonly AD_PAUSE: string = 'ad_pause';
  static readonly AD_PLAY: string = 'ad_play';
  static readonly AD_RESUME: string = 'ad_resume';
  static readonly AD_STARTED: string = 'ad_started';
  static readonly AD_PROGRESS: string = 'ad_progress';
  //Fired when an ad break has ended
  static readonly AD_BREAK_ENDED: string = 'break_end';
  //Fired when an ad break has been skipped
  static readonly BREAK_SKIPPED: string = 'break_skipped';
  //Fired when an ad break has begun
  static readonly AD_BREAK_STARTED: string = 'break_start';
  //Fired when an ad has completed
  static readonly AD_COMPLETE: string = 'ad_complete';
  //Fired when the first quartile of an ad has been reached
  static readonly FIRST_QUARTILE: string = 'first_quartile';
  //Fired when the midpoint of an ad has been reached
  static readonly MIDPOINT: string = 'midpoint';
  //Fired when an ad request has been made
  static readonly REQUEST: string = 'ad_request';
  //Fired when an ads request has completed.
  static readonly REQUEST_COMPLETE: string = 'ad_request_complete';
  static readonly REQUEST_START: string = 'ad_request_start';
  //Fired when an ad has been skipped
  static readonly SKIPPED: string = 'skipped';
  static readonly THIRD_QUARTILE: string = 'third_quartile';
  static readonly PRE_ROLL_COMPLETED: string = 'pre_roll_completed';
  static readonly AD_BLOCKER_DETECTED: string = 'ad_blocker_detected';
  static readonly STREAM_LOADED: string = 'stream_loaded';
  static readonly STREAM_LOAD_ERROR: string = 'stream_load_error';
  static readonly AD_CREATED: string = 'ad_created';
  static readonly AD_IMPRESSION: string = 'ad_impression';
  static readonly SECOND_QUARTILE: string = 'second_quartile';
  static readonly LOADED: string = 'loaded';
  static readonly COMPLETE: string = 'complete';
  static readonly ERROR: string = 'error';
  static readonly CLICK: string = 'click';
  static readonly PAUSED: string = 'paused';
  static readonly RESUMED: string = 'resumed';
  static readonly ALL_ADS_COMPLETED: string = 'all_ads_completed';
  static readonly CONTENT_PAUSE_REQUESTED: string = 'content_pause_requested';
  static readonly CONTENT_RESUME_REQUESTED: string = 'content_resume_requested';
}
