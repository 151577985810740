export enum FeatureFlagConstant {
  CONFIG_TOBACCO = 'config_tobacco',
  CONFIG_GOOGLE_IMA = 'config_google_ima',
  CONFIG_PLAYER_RELOAD = 'config_player_reload',
  CONFIG_SPORTS = 'config_sports',
  FEATURE_ABR_CAPPING = 'feature_abr_capping',
  FEATURE_MIMETYPE_CHECK_ENABLED = 'feature_mimetype_check_enabled',
  CONFIG_PLAYER_OPTIONS_MOBILE = 'config_player_options_mobile',
  CONFIG_PLAYER_OPTIONS_DESKTOP = 'config_player_options_desktop',
  CONFIG_REGISTRATION_POPUP = 'config_regitration_popup',
  CONFIG_ADS = 'config_ads'
}
