import videojs from 'video.js';

import { PlayerConfig } from './configs/PlayerConfig';
import { TextTrack } from './configs/TextTrack';
import { playerEventsConstants } from './constants/playerConstants';
import { Error, ErrorCategory, ErrorCode, ErrorSeverity } from './error/Error';
import { AdEvents } from './events/AdEvents';
import { Events } from './events/Events';
import { IMediaPlayer } from './IMediaPlayer';

// import 'videojs-contrib-eme';
import eme from 'videojs-contrib-eme';
import 'videojs-landscape-fullscreen';
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import 'videojs-vtt-thumbnails';
import EventEmitter from 'events';

import { Media } from './configs/Media';
import { createSubtitleButton } from './components/SubtitleButton';
import { addSeekButtons, seek } from './components/SeekButton';
import { AdsManager } from './advertisement/AdsManager';
import { AdClient } from './configs/AdConfig';
// import 'videojs-sprite-thumbnails';
// import { VideoThumNail } from './configs/VideoThumbNail';
import { CastSender } from './cast/CastSender';
import {
  getLanguageName,
  getBrowserType,
  getBitrateSelectedText,
  isMobileDevice
} from './utils/Utils';

import { detectAnyAdblocker } from 'just-detect-adblock';

declare global {
  interface Window {
    enableReloadPlayerOnTimeout: any;
    clearPlayerReloadTimer: any;
  }
}
export class MediaPlayer extends EventEmitter implements IMediaPlayer {
  absoluteCurrentTime = 0;
  absoluteDuration = 0;
  adsManager: AdsManager | undefined;
  castSender: CastSender | undefined | null;
  videoPlayer: any;
  videoElement: HTMLElement | null;
  playerConfig: any;
  playerContainer: HTMLElement | null | undefined;
  previousTime = 0;
  currentTime = 0;
  seekStart: null | undefined | number = 0;
  selectedQualityLevel = 0;
  mediaConfig: Media | undefined;
  autoPlayFailed = false;
  isPlayingEventSent = false;
  adsUtilsData: any;

  defaultOptions = {
    autoplay: true,
    muted: true,
    controls: true,
    errorDisplay: false,
    width: 640,
    height: 480,
    html5: {
      vhs: {
        overrideNative: !videojs.browser.IS_SAFARI || false,
        useNetworkInformationApi: true,
        experimentalBufferBasedABR: true
      },
      nativeTextTracks: false,
      nativeAudioTracks: false,
      nativeVideoTracks: false
    },
    controlBar: {
      pictureInPictureToggle: false
    },
    userActions: {
      hotkeys: function (this: any, event: any) {
        event.preventDefault();
        const volumeStep = 0.1;
        // "f key" fullscreen mode toggling
        if (event.which === 70) {
          this.isFullscreen()
            ? this.exitFullscreen()
            : this.requestFullscreen();
        }
        // "space key" or "k" pressed
        if (event.which === 32 || event.which === 75) {
          this.paused() ? this.play() : this.pause();
        }
        // right and left arrows functions when the content is not live
        if (!this.liveTracker.isLive()) {
          // seeking forward on "right arrow" and "l" pressed
          if (event.which === 39 || event.which === 76) {
            seek(10, this);
          }
          // seeking forward on "left arrow" and "j" pressed
          if (event.which === 37 || event.which === 74) {
            seek(-10, this);
          }
        }
        // "up arrow" function for volume up
        if (event.which === 38) {
          if (this.muted()) {
            this.muted(false);
          }
          this.volume(this.volume() + volumeStep);
        }
        // "down arrow" function for volume down
        if (event.which === 40) {
          this.volume(this.volume() - volumeStep);
        }
        // "m" key functionality for muted volume toggling
        if (event.which === 77) {
          this.muted() ? this.muted(false) : this.muted(true);
        }
      }
    }
  };

  // constructor function which accept video element as param
  constructor(videoTagId: Element, adsUtilsData: any) {
    super();
    this.adsUtilsData = adsUtilsData;
    this.videoElement = document.getElementById(videoTagId.toString());
    if (this.videoElement) {
      const browserType = getBrowserType();
      if (browserType === 'Safari') {
        this.videoElement?.classList.add('isSafariBrowser');
      }
      this.playerContainer = this.videoElement.parentElement;
    }
  }

  // setting up the video player
  setup(config: PlayerConfig, mediaConfig: Media): void {
    console.log('IS_SAFARI :::::');
    console.log(!videojs.browser.IS_SAFARI);
    this.playerConfig = config;
    this.mediaConfig = mediaConfig;
    this.selectedQualityLevel = 0;
    if (isMobileDevice()) {
      this.defaultOptions.html5 = {
        ...this.defaultOptions.html5,
        vhs: {
          ...this.defaultOptions.html5.vhs,
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          limitRenditionByPlayerDimensions: false,
          externVhs: {
            GOAL_BUFFER_LENGTH: 15
          }
        } as any,
        hlsjsConfig: { startLevel: 0 }
      } as any;
    }
    const options = videojs.mergeOptions(this.defaultOptions, config);
    videojs.registerPlugin('eme', eme);
    if (this.videoElement !== null) {
      this.videoPlayer = videojs(this.videoElement, options);
      window.videoPlayer = this.videoPlayer;
      // videojsHlsjsSourceHandler.register(videojs);
      window.videojs = videojs;
      this.videoPlayer.httpSourceSelector();
      this.videoPlayer.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: true
        }
      });

      this.adsManager = new AdsManager(
        this.videoPlayer,
        this.videoElement,
        this.adsUtilsData
      );
      this.castSender = new CastSender(this.videoPlayer, this.adsManager);
      createSubtitleButton(this.videoPlayer);
      this.registerEvents();
      if (!config.isSportsLive) {
        this.addSeekButtons();
      }
    } else {
      console.error('Element not found');
      console.log(config, mediaConfig);
    }
  }

  setSource(mediaConfig: Media | undefined) {
    this.mediaConfig = mediaConfig;
    detectAnyAdblocker().then((detected: any) => {
      if (!detected && mediaConfig && mediaConfig.advertising != null) {
        this.adsManager?.setConfig(mediaConfig);
        this.registerAdEvents(this.adsManager);
        if (mediaConfig.advertising.client == AdClient.DAI) return;
      } else {
        this.emit(AdEvents.AD_BLOCKER_DETECTED, this.videoPlayer);
      }
      this.videoPlayer.error(null);
      if (this.videoPlayer.eme) {
        this.videoPlayer.eme.activeSrc = null;
      }
      try {
        this.videoPlayer.eme();
      } catch (error: any) {
        const eventPayload = {
          type: 'EME_ERROR',
          payload: error.message
        };
        this.sendConvivaReportEvent(eventPayload);
      }
      this.videoPlayer.src(mediaConfig);
    });
    const eventPayload = {
      type: 'SET_SOURCE_FOR_PLAYER'
    };
    this.sendConvivaReportEvent(eventPayload);
  }

  // resetting the player
  clearSource() {
    if (this.videoPlayer != undefined) {
      if (this.adsManager) this.adsManager.destroy();
      this.videoPlayer.reset();
    }
  }

  // code creates and returns video element
  private getVideoElement(): HTMLMediaElement {
    const video = document.createElement('video') as HTMLMediaElement;
    video.id = 'video-tag';
    video.className = 'playerComp content video-js vjs-big-play-centered';
    video.preload = 'auto';
    video.controls = true;
    video.setAttribute('playsinline', 'true');
    const eventPayload = {
      type: 'CREATE_VIDEO_ELEMENT'
    };
    this.sendConvivaReportEvent(eventPayload);

    return video;
  }

  reset() {
    this.videoPlayer.reset();
    const eventPayload = {
      type: 'VIDEO_PLAYER_RESET'
    };
    this.sendConvivaReportEvent(eventPayload);
  }

  registerAdEvents(adsManager: AdsManager | undefined) {
    if (adsManager) {
      adsManager.on(AdEvents.AD_STARTED, () => this.emit(AdEvents.AD_STARTED));
      adsManager.on(AdEvents.AD_COMPLETE, () =>
        this.emit(AdEvents.AD_COMPLETE)
      );
      adsManager.on(AdEvents.PRE_ROLL_COMPLETED, () =>
        this.emit(AdEvents.PRE_ROLL_COMPLETED)
      );
      adsManager.on(AdEvents.AD_ERROR, (adError) =>
        this.emit(AdEvents.AD_ERROR, adError)
      );
      adsManager.on(
        AdEvents.STREAM_LOADED,
        this.googleDAIStreamLoaded.bind(this)
      );
      adsManager.on(
        AdEvents.STREAM_LOAD_ERROR,
        this.googleDAIStreamLoadError.bind(this)
      );
    }
  }

  // Google DAI stream loaded listener function
  googleDAIStreamLoaded(streamURL: string) {
    if (this.mediaConfig) {
      this.mediaConfig.src = streamURL;
    }
    this.videoPlayer.eme();
    this.videoPlayer.src(this.mediaConfig);
  }

  // Google DAI stream load error listener function
  googleDAIStreamLoadError() {
    this.videoPlayer.src(this.mediaConfig);
  }

  // registering all player relared events
  registerEvents() {
    this.videoPlayer.on('ready', this.onPlayerReady.bind(this));
    this.videoPlayer.on('loadstart', this.onLoadStart.bind(this));
    this.videoPlayer.on('ended', this.onPlayEndedEvent.bind(this));
    this.videoPlayer.on('error', this.onPlayerError.bind(this));
    this.videoPlayer.on('loadeddata', this.onPlayerEvents.bind(this));
    this.videoPlayer.on('loadedmetadata', this.onMetaDataLoaded.bind(this));
    this.videoPlayer.on('timeupdate', this.onTimeUpdate.bind(this));
    this.videoPlayer.on('volumechange', this.onVolumeChange.bind(this));
    this.videoPlayer.on('suspend', this.onPlayerEvents.bind(this));
    this.videoPlayer.on('emptied', this.onPlayerEvents.bind(this));
    this.videoPlayer.on('stalled', this.onPlayerEvents.bind(this));
    this.videoPlayer.on('resize', this.onPlayerEvents.bind(this));
    this.videoPlayer.on('durationchange', this.onDurationChanged.bind(this));
    this.videoPlayer.on('play', this.onPlayEvent.bind(this));
    this.videoPlayer.on('fullscreenchange', this.onFullScreenEvent.bind(this));
    this.videoPlayer.on('seeked', this.onSeekEvent.bind(this));
    this.videoPlayer.on('seeking', this.onSeekingEvent.bind(this));
    this.videoPlayer.on('pause', this.onPauseEvent.bind(this));
    this.videoPlayer.on('subtitleChanged', this.onSubtitleChange.bind(this));
    this.videoPlayer.on('progress', this.onPlayerProgressEvent.bind(this));
    this.videoPlayer.on('dispose', this.onPlayerDisposeEvent.bind(this));
    this.videoPlayer.on('mute', this.onPlayerMute.bind(this));
    this.videoPlayer.on('click', this.onPlayerClick.bind(this));
    this.videoPlayer.on('dblclick', this.onPlayerDblClick.bind(this));
    this.videoPlayer.on('playing', this.onPlayingEvent.bind(this));
  }

  private onPlayerDblClick(event: any) {
    if (event && event.target && event.target.parentElement) {
      if (event.target.parentElement.classList.contains('emoji-reaction')) {
        event.preventDefault();
        this.videoPlayer.userActivity_ = false;
        return;
      }
    }
    event.preventDefault();
    if (
      event &&
      event.target &&
      event.target.parentElement &&
      !isMobileDevice() &&
      this.videoPlayer &&
      !isMobileDevice() &&
      event.target.parentElement.classList.contains('playerContainer')
    ) {
      this.videoPlayer.isFullscreen()
        ? this.videoPlayer.exitFullscreen()
        : this.videoPlayer.requestFullscreen();
    }
  }

  private onPlayerClick(event: any) {
    if (event && event.target && event.target.parentElement) {
      if (event.target.parentElement.classList.contains('emoji-reaction')) {
        event.preventDefault();
        this.videoPlayer.userActivity_ = false;
        return;
      }
      if (!isMobileDevice()) {
        if (this.videoPlayer) {
          if (
            event.target.parentElement.classList.contains('playerContainer')
          ) {
            this.videoPlayer.paused() ? this.play() : this.pause();
          }
        }
      }
      console.log(event.target.parentElement.classList.value);
      if (
        event.target.parentElement.classList.value.includes(
          'vjs-http-source-selector'
        )
      ) {
        this.emit(playerEventsConstants.qualityBtnClicked, this.videoPlayer);
      } else if (
        event.target.parentElement.classList.value.includes('vjs-audio-button')
      ) {
        this.emit(playerEventsConstants.audioBtnClicked, this.videoPlayer);
      } else if (
        event.target.parentElement.classList.value.includes(
          'vjs-subtitles-button'
        )
      ) {
        const eventPayload = {
          type: 'subtitleBtnClicked'
        };
        window.convivaAnalytic.reportEvent('customEvent', eventPayload);
        this.emit(playerEventsConstants.subtitleBtnClicked, this.videoPlayer);
      } else if (
        event.target.parentElement.classList.value.includes('vjs-mute-control')
      ) {
        this.emit(playerEventsConstants.muteBtnClicked, this.videoPlayer);
      }
    }
  }

  private onPlayingEvent() {
    if (!this.isPlayingEventSent) {
      const eventPayload = {
        type: 'FIRST_FRAME_RENDERED',
        TIME: Date.now()
      };
      this.sendConvivaReportEvent(eventPayload);
      window?.storeMixPanelMetricsInSS('FIRST_FRAME_RENDERED', Date.now());
      this.isPlayingEventSent = true;
      if (this.autoPlayFailed) {
        this.sendUserWaitEvent(window.Conviva.Constants.Events.USER_WAIT_ENDED);
      }
      if (window) {
        window.isPlayingEventSent = true;
        if (window.enableReloadPlayerOnTimeout) {
          window.clearPlayerReloadTimer();
        }
      }
    }
  }

  // videojs dispose listener function
  private onPlayerDisposeEvent() {
    this.emit(Events.DISPOSE);
    console.log('Player disposed');
    this.videoPlayer = null;
    this.videoElement = this.getVideoElement();
    this.playerContainer?.appendChild(this.videoElement);
    const eventPayload = {
      type: 'PLAYER_DISPOSED'
    };
    this.sendConvivaReportEvent(eventPayload);
    window.isPlayingEventSent = false;
  }

  addSeekButtons(): void {
    addSeekButtons(this.videoPlayer);
  }

  // on player load start listener function
  private onLoadStart() {
    window.firstRequestTriggered = false;
    // if (window && window.userInteracted) {
    //   this.videoPlayer.muted(false);
    //   this.videoPlayer.volume(1);
    // }
    this.emit(Events.LOAD_START);
    const promise = this.videoPlayer.play();

    if (typeof promise !== 'undefined') {
      promise
        .then(() => {
          const eventPayload = {
            type: 'AUTO_PLAY_WORKED'
          };
          this.sendConvivaReportEvent(eventPayload);
          console.log('Autoplay worked!');
        })
        .catch((error) => {
          // report user wait start event and maintain a flag saying wait event has started
          //retry if auto play fails by mute the player...
          this.sendUserWaitEvent(
            window.Conviva.Constants.Events.USER_WAIT_STARTED
          );
          this.autoPlayFailed = true;

          if (
            this.videoPlayer &&
            this.videoPlayer.player &&
            this.videoPlayer.player()
          ) {
            this.videoPlayer.muted(true);
            this.videoPlayer.volume(0);
            const promise = this.videoPlayer.play();
            if (typeof promise !== 'undefined') {
              promise
                .then(() => {
                  const eventPayload = {
                    type: 'AUTO_PLAY_RETRY_WORKED'
                  };
                  this.sendConvivaReportEvent(eventPayload);
                })
                .catch(() => {
                  const eventPayload = {
                    type: 'AUTO_PLAY_RETRY_NOT_WORKED'
                  };
                  this.sendConvivaReportEvent(eventPayload);
                });
            }
          }
        });
    }
  }

  // videojs player ready listener function
  private onPlayerReady() {
    this.setSource(this.mediaConfig);
    this.emit(Events.READY, this.videoPlayer);

    const eventPayload = {
      type: 'PLAYER_READY'
    };

    this.sendConvivaReportEvent(eventPayload);
    if (this.playerConfig.logo) this.renderWatermark(this.playerConfig.logo);
  }

  sendConvivaReportEvent(eventPayload: any) {
    if (
      window &&
      window.convivaAnalytic &&
      window.convivaAnalytic.reportEvent
    ) {
      window.convivaAnalytic.reportEvent('customEvent', eventPayload);
    }
  }

  sendUserWaitEvent(eventPayload: any) {
    if (
      window &&
      window.convivaAnalytic &&
      window.convivaAnalytic.reportEvent
    ) {
      window.convivaAnalytic.reportEvent(eventPayload);
    }
  }

  // videojs player mute listener function
  private onPlayerMute(event: any) {
    console.log(event.type);
    this.emit(Events.MUTE, this.videoPlayer);
  }

  // videojs player event listener function
  private onPlayerEvents() {
    //do nothing
  }

  // listening for player errors
  private onPlayerError() {
    const mediaError: MediaError = this.videoPlayer.error();
    if (mediaError.code == MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      console.log('SRC NOT SUPPORTED ERROR');
      console.error(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED,
          ErrorCategory.MEDIA,
          ErrorSeverity.CRITICAL,
          mediaError.message
        )
      );
    } else if (mediaError.code == MediaError.MEDIA_ERR_NETWORK) {
      console.log('NETWORK RELATED ERROR');
      console.log(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.NETWORK,
          ErrorCategory.NETWORK,
          ErrorSeverity.RECOVERABLE,
          mediaError.message
        )
      );
    } else if (mediaError.code == MediaError.MEDIA_ERR_DECODE) {
      console.log('DECODER ERROR');
      console.log(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.DECODER,
          ErrorCategory.MANIFEST,
          ErrorSeverity.CRITICAL,
          mediaError.message
        )
      );
    } else if (mediaError.code == MediaError.MEDIA_ERR_ABORTED) {
      console.log('MEDIA ABORTED');
      console.log(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.ABORTED,
          ErrorCategory.MEDIA,
          ErrorSeverity.CRITICAL,
          mediaError.message
        )
      );
    } else if (mediaError.code == 5) {
      // "For Error Code 5, Failed to execute 'update' on 'MediaKeySession': Rejected with system code (13)"
      console.log(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.MEDIAKEYSYSTEMS,
          ErrorCategory.MEDIAKEYSYSTEMS,
          ErrorSeverity.CRITICAL,
          mediaError.message
        )
      );
    } else {
      console.log('OTHER ERROR');
      console.log(mediaError);
      this.emit(
        Events.ERROR,
        new Error(
          ErrorCode.CUSTOM,
          ErrorCategory.STREAMING,
          ErrorSeverity.CRITICAL,
          mediaError.message
        )
      );
    }
  }

  // code for adding subtitle tracks
  addTextTracks(tracks: TextTrack[]): void {
    if (this.videoPlayer != undefined) {
      const oldTracks = this.videoPlayer.textTracks();
      let i = oldTracks.length;
      if (i) {
        while (i--) {
          if (oldTracks[i].kind === 'subtitles') {
            //remove tracks from manifest
            this.videoPlayer.removeRemoteTextTrack(oldTracks[i]);
          }
        }
      }
      if (tracks.length) {
        tracks.forEach((value) => {
          //adding tracks from spapi
          this.videoPlayer.addRemoteTextTrack(value);
        });
      }
    }
    this.registerTrackChange();
  }
  
  applySubtitleChangesOnAudioChange(
    tracks: TextTrack[],
    audioLanguage: string
  ): void {
    if (this.videoPlayer != undefined) {
      const oldTracks = this.videoPlayer.textTracks();
      let i = oldTracks.length;
      if (i) {
        while (i--) {
          if (oldTracks[i].kind === 'subtitles') {
            //remove tracks from manifest
            this.videoPlayer.removeRemoteTextTrack(oldTracks[i]);
          }
        }
      }

      if (tracks.length) {
        let subtitlePreference: { [key: string]: string } | null = null;
        let languageMatch = '';
        const storedSubtitlePreference =
          localStorage.getItem('subtitlePreference');
        if (storedSubtitlePreference !== null) {
          // Check if the value exists in localStorage
          try {
            subtitlePreference = JSON.parse(storedSubtitlePreference);
            if (subtitlePreference && subtitlePreference[audioLanguage]) {
              languageMatch = subtitlePreference[audioLanguage];

            }
          } catch (error) {
            console.error('Error parsing subtitlePreference:', error); // Handle parsing error
          }
        }
        tracks.forEach((value) => {
          if (
            languageMatch &&
            value['srclang'].toLowerCase() == languageMatch.toLowerCase()
          ) {
            value.mode = 'showing';
          }
          this.videoPlayer.addRemoteTextTrack(value);
        });
      }
    }
  }

  getActiveLanguageTrack() {
    let activeLanguageTrack = null;
    for (
      let index = 0;
      index < this.videoPlayer.audioTracks().tracks_.length;
      index++
    ) {
      if (this.videoPlayer.audioTracks().tracks_[index].enabled) {
        activeLanguageTrack =
          this.videoPlayer.audioTracks().tracks_[index].language || '';
        return activeLanguageTrack;
      }
    }
    return activeLanguageTrack;
  }

  getCastSender() {
    return this.castSender;
  }

  //map audio tracks language
  modifyAudioTracksLabel(audioLanguages: [] = [], streamLanguage?: any) {
    if (this.videoPlayer != undefined) {
      const audioTracks = this.videoPlayer.audioTracks();
      if (streamLanguage && audioLanguages?.length > 0) {
        // fix for safari & mobile
        if (!audioTracks.length) {
          this.addDummyAudioTracks(streamLanguage, true);
        }
        audioLanguages.forEach((item: any) => {
          if (item !== streamLanguage) {
            this.addDummyAudioTracks(item);
          }
        });
        this.changeDefaultAudioLabel(audioTracks, streamLanguage);
      }
      for (let i = 0; i < audioTracks.length; i++) {
        const track = audioTracks[i];
        track.label = getLanguageName(track.language);
      }

      audioTracks.on(
        'change',
        function action(this: any) {
          const audioTracks = this.videoPlayer.audioTracks();
          let audioLanguage = 'NA';
          for (let i = 0; i < audioTracks.length; i++) {
            const track = audioTracks[i];
            if (track.enabled) {
              audioLanguage = track.language;
            }
          }
          this.emit('audioChanged', this.videoPlayer, audioLanguage);
        }.bind(this)
      );
    }
  }

  addDummyAudioTracks(item: any, enabled = false) {
    // Create a track object.
    const track = new videojs.AudioTrack({
      id: `${item}-audio-track`,
      kind: 'translation',
      label: item,
      language: item,
      enabled: enabled
    });

    // Add the track to the player's audio track list.
    this.videoPlayer.audioTracks().addTrack(track);
  }

  changeDefaultAudioLabel(audioTracks: string | any[], streamLanguage: any) {
    for (let i = 0; i < audioTracks.length; i++) {
      const track = audioTracks[i];
      if (track.label === 'default') {
        track.language = streamLanguage;
        track.label = getLanguageName(streamLanguage);
      }
      if (track.label === 'Audio2_8') {
        track.language = streamLanguage;
        track.label = getLanguageName(streamLanguage);
      }
    }
  }

  setVttThumbnailSrc(vttSrc: string) {
    this.videoPlayer.vttThumbnails({
      src: vttSrc
    });
  }

  //map audio tracks language
  private videoQualityChange = () => {
    const qualityLevels = this.videoPlayer.qualityLevels();
    qualityLevels.on(
      'change',
      function (this: any) {
        if (
          qualityLevels &&
          qualityLevels.length >= qualityLevels.selectedIndex &&
          this.selectedQualityLevel !== qualityLevels.selectedIndex
        ) {
          const selectedQuality = getBitrateSelectedText(qualityLevels);
          if (selectedQuality) {
            this.selectedQualityLevel = qualityLevels.selectedIndex;
            this.emit(Events.QUALITY_CHANGE, selectedQuality, this.videoPlayer);
            const eventPayload = {
              type: 'QUALITY_LEVEL_CHANGE',
              payload: JSON.stringify(selectedQuality)
            };
            this.sendConvivaReportEvent(eventPayload);
          }
        }
      }.bind(this)
    );
  };

  registerTrackChange() {
    this.videoPlayer.textTracks().on(
      'change',
      function action(this: any) {
        this.videoPlayer.trigger('subtitleChanged');
      }.bind(this)
    );
  }

  // code for adding watermark to the player
  private renderWatermark(logo: string) {
    const videoEl = this.videoPlayer.el();
    const div = document.createElement('div');
    const image = document.createElement('img');
    div.classList.add('vjs-watermark-content');
    image.src = logo;
    div.appendChild(image);
    videoEl.appendChild(div);
  }

  // videojs timeupdate event listener function
  private onTimeUpdate() {
    this.previousTime = this.currentTime;
    this.currentTime = this.videoPlayer.currentTime();
    this.emit(Events.TIME_UPDATE, this.videoPlayer);
  }

  // videojs seek event listener function
  private onSeekEvent() {
    const seekDetails = {
      from: this.seekStart,
      to: this.currentTime
    };
    this.seekStart = null;
    this.emit(Events.SEEKED, this.videoPlayer, seekDetails);
    const eventPayload = {
      type: 'ON_SEEK_EVENT'
    };
    this.sendConvivaReportEvent(eventPayload);
  }

  // videojs seek event listener function
  private onSeekingEvent() {
    if (this.seekStart === null) {
      this.seekStart = this.previousTime;
    }
  }

  // videojs volume change listener function
  private onVolumeChange() {
    this.emit(Events.VOLUME_CHANGE, this.videoPlayer);
  }

  // videojs metadata changed listener function
  private onMetaDataLoaded() {
    // console.log("VST:META_DATA_LOADED",new Date());
    // this.modifyAudioTracksLabel();
    this.videoQualityChange();
    this.emit(Events.META_DATA_LOADED, this.videoPlayer);
    const eventPayload = {
      type: 'META_DATA_LOADED'
    };
    this.sendConvivaReportEvent(eventPayload);
  }

  // videojs play event listener function
  private onPlayEvent() {
    this.emit(Events.PLAY, this.videoPlayer);
  }

  private onPauseEvent() {
    this.emit(Events.PAUSE, this.videoPlayer);
  }

  // videojs playback end listener function
  private onPlayEndedEvent() {
    this.emit(Events.ENDED, this.videoPlayer);
  }

  // videojs fullscreen toggle listener function
  private onFullScreenEvent() {
    this.emit(Events.FULL_SCREEN_CHANGE, this.videoPlayer);
  }

  // videojs subtitle change listener function
  private onSubtitleChange() {
    this.emit(Events.SUBTITLECHANGE, this.videoPlayer);
  }

  // videojs video progress listener function
  private onPlayerProgressEvent() {
    if (window && !window.isLive) {
      this.emit(Events.PROGRESS, this.videoPlayer);
    }
  }

  // videojs duration changed listener function
  private onDurationChanged() {
    const controlBar = this.videoPlayer.controlBar;
    const durationDisplay = controlBar.getChild('DurationDisplay');
    const currentTimeDisplay = controlBar.getChild('CurrentTimeDisplay');
    const timeDivider = controlBar.getChild('TimeDivider');
    let skipBackButton;
    let skipForButton;
    if (
      document.getElementsByClassName('vjs-skip-backward').length &&
      document.getElementsByClassName('vjs-skip-forward').length
    ) {
      skipBackButton = document.getElementsByClassName('vjs-skip-backward')[0];
      skipForButton = document.getElementsByClassName('vjs-skip-forward')[0];
    }
    if (
      this.videoPlayer.liveTracker.isLive() ||
      !isFinite(this.videoPlayer.duration())
    ) {
      durationDisplay.show();
      currentTimeDisplay.show();
      timeDivider.show();
      if (skipBackButton && skipForButton) {
        skipBackButton.remove();
        skipForButton.remove();
      }
    } else {
      durationDisplay.show();
      currentTimeDisplay.show();
      timeDivider.show();
    }
  }

  destroy(): void {
    if (this.videoPlayer != undefined) {
      if (this.adsManager) this.adsManager.destroy();
      this.castSender = null;
      // commenting for an error - Avinash - 25May
      // this.videoPlayer.reset()
      this.deregisterEvents();
      this.videoPlayer.dispose();
      this.videoPlayer = null;
    }
  }

  deregisterEvents() {
    this.videoPlayer.off('ready', this.onPlayerReady.bind(this));
    this.videoPlayer.off('loadstart', this.onLoadStart.bind(this));
    this.videoPlayer.off('ended', this.onPlayEndedEvent.bind(this));
    this.videoPlayer.off('error', this.onPlayerError.bind(this));
    this.videoPlayer.off('loadeddata', this.onPlayerEvents.bind(this));
    this.videoPlayer.off('loadedmetadata', this.onMetaDataLoaded.bind(this));
    this.videoPlayer.off('timeupdate', this.onTimeUpdate.bind(this));
    this.videoPlayer.off('volumechange', this.onVolumeChange.bind(this));
    this.videoPlayer.off('suspend', this.onPlayerEvents.bind(this));
    this.videoPlayer.off('emptied', this.onPlayerEvents.bind(this));
    this.videoPlayer.off('stalled', this.onPlayerEvents.bind(this));
    this.videoPlayer.off('resize', this.onPlayerEvents.bind(this));
    this.videoPlayer.off('durationchange', this.onDurationChanged.bind(this));
    this.videoPlayer.off('play', this.onPlayEvent.bind(this));
    this.videoPlayer.off('fullscreenchange', this.onFullScreenEvent.bind(this));
    this.videoPlayer.off('seeked', this.onSeekEvent.bind(this));
    this.videoPlayer.off('seeking', this.onSeekingEvent.bind(this));
    this.videoPlayer.off('pause', this.onPauseEvent.bind(this));
    this.videoPlayer.off('subtitleChanged', this.onSubtitleChange.bind(this));
    this.videoPlayer.off('progress', this.onPlayerProgressEvent.bind(this));
    this.videoPlayer.off('dispose', this.onPlayerDisposeEvent.bind(this));
    this.videoPlayer.off('mute', this.onPlayerMute.bind(this));
    this.videoPlayer.off('dblclick', this.onPlayerDblClick.bind(this));
  }

  end(): void {
    if (this.videoPlayer != undefined) {
      this.videoPlayer.pause();
      this.videoPlayer.currentTime = 0;
    }
  }

  enterFullScreen(): void {
    if (this.videoPlayer != undefined && !this.videoPlayer.isFullscreen()) {
      this.videoPlayer.requestFullscreen();
    }
  }

  exitFullScreen(): void {
    if (this.videoPlayer != undefined && this.videoPlayer.isFullscreen()) {
      this.videoPlayer.exitFullscreen();
    }
  }

  enterPictureInPicture(): void {
    if (document.pictureInPictureEnabled) {
      this.videoPlayer.requestPictureInPicture();
    }
  }

  exitPictureInPicture(): void {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  }

  goLive(): void {
    if (this.videoPlayer != undefined && this.videoPlayer.isLive()) {
      this.videoPlayer.seekToLiveEdge();
    }
  }

  mute(): void {
    if (this.videoPlayer != undefined) {
      this.videoPlayer.muted(true);
    }
  }

  unmute(): void {
    if (this.videoPlayer != undefined) {
      this.videoPlayer.muted(false);
    }
  }

  getMutedState() {
    if (this.videoPlayer != undefined) {
      return (
        this.videoPlayer.el != undefined &&
        this.videoPlayer.muted &&
        this.videoPlayer.muted()
      );
    }
  }

  pause(): void {
    if (this.videoPlayer != undefined) {
      if (this.adsManager && this.adsManager.isAdBreak()) {
        this.adsManager.pauseAd();
        return;
      }
      this.videoPlayer.pause();
    }
  }

  play(): void {
    if (this.videoPlayer != undefined) {
      if (this.adsManager && this.adsManager.isAdBreak()) {
        this.adsManager.resumeAd();
        return;
      }
      this.videoPlayer.play();
    }
  }

  replay(): void {
    if (this.videoPlayer != undefined) {
      this.videoPlayer.currentTime = 0;
      this.videoPlayer.play();
    }
  }

  seek(value: number): void {
    if (this.videoPlayer != undefined) {
      this.videoPlayer.currentTime = value;
    }
  }
}
