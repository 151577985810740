export const constants = {
  widevineLicenseUri: 'widevine/getLicense',
  fairPlayCertificateUri:
    'https://stcf-prod.zee5.com/prod/zee5Videojs/safari/b19d422d890644d5112d08469c2e5946.der',
  fairPlayLicenseUri: 'fairplay/getLicense',
  mandatoryPopupCount: 500,
  mandatoryVideoCount: 4,
  audioSelectionTimeout: 5000
};

export const playerEventsConstants = {
  qualityChange: 'Quality Changed',
  subTitleChange: 'Subtitle Language Change',
  audioLanguageChange: 'Audio Language Change',
  seek_scrub: 'Scrub/Seek',
  firstPlay: 'firstplay',
  onReplay: ' onReplay',
  playbackEnded: 'playbackended',
  skipIntro: 'Skip Intro',
  watchCredits: 'Watch Credits',
  resume: 'Resume',
  muteChanged: 'mutechanged',
  exitBeforeAdStart: 'exitBeforeAdStart',
  ribbin_ctas: 'Ribbon CTAs',
  playerCtas: 'Player CTAs',
  ribbonImpression: 'Ribbon Impression',
  adCompleted: 'adcompleted',
  adSkipped: 'adskipped',
  adErrors: 'aderrors',
  userClosedAd: 'userclosedad',
  adClicked: 'adclicked',
  adStarted: 'adstarted',
  adPaused: 'adpaused',
  adInitialized: 'Ad Initialized',
  pauseEvent: 'pauseEvent',
  goLiveEvent: 'goLiveEvent',
  adView: 'Ad View',
  playEvent: 'playEvent',
  autoSeek: 'autoSeek',
  audioBtnClicked: 'audioBtnClicked',
  qualityBtnClicked: 'qualityBtnClicked',
  subtitleBtnClicked: 'subtitleBtnClicked',
  muteBtnClicked: 'muteBtnClicked',
  updateBufferPlayHeadTime: 'updateBuffer&PlayHeadTime',
  Not_Applicable: 'Not-Applicable',
  ad_created: 'Tech AD Loader Created',
  ad_request: 'Ad Request',
  ad_response: 'Ad Response',
  ad_impression: 'Ad Impression',
  PARTNER_NAME: 'partnerName',
  DEFAULT_PARTNER_ZEE5: 'ZEE5',
  PLAN_ID: 'planID',
  PLAYER_VIEW_CHANGED: 'Player View Changed',
  PLAYBACK_ERROR: 'Playback Error',
  PLAY_BACK_TRIGGERED_TYPE: 'playBackTriggeredType',
  adSecondQuartile: 'second_quartile',
  adThirdQuartile: 'third__quartile',
  adRequest: 'ad_request',
  adImpression: 'ad_impression',
  adFirstQuartile: 'first_quartile',
  healthSpotViewed: 'Healthspot Viewed',
  PIN_SCOREBOARD: 'Pin Scoreboard',
  PIN: 'Pin',
  UNPIN: 'Unpin',
  SCORE_CLICK: 'Score Click',
  ILT_ERROR_FALLBACK: 'ILT DAI Fallback'
};

export const castEventsConstants = {
  CAST_ENDED: 'Casting Ended',
  CAST_FAILED: 'Casting Failed',
  CAST_STARTED: 'Casting Started'
};

export const videoQualityMixpanelOptions = {
  Auto: 'Auto',
  Auto96p: 'Auto - Recommended',
  DataSaverUpto240p: 'Data Saver - 240p',
  DataSaver: 'Data Saver',
  SDUpto480p: 'SD - 480p',
  SD: 'SD',
  HDUpto720p: 'HD - 720p',
  HD: 'HD',
  FullHDUpto1080p: 'Full HD - 1080p',
  FullHD: 'Full HD'
};

export const playBackType = {
  UpNext_Auto: 'UpNext Auto',
  UpNext_Manual: 'UpNext Manual',
  LANGUAGE_CHANGE: 'Language Change',
  Subtitle_Language_Change: 'Subtitle Language Change',
  Video_Resolution_Change: 'Video Resolution Change'
};
