export function addSkipIntroButton(
  playerRef: any,
  skipIntroEndTime: any,
  skipIntroStartTime: any,
  analyticsManager: {
    reportEvent: (
      arg0: string,
      arg1: {introStartTime: any; introEndTime: any}
    ) => void;
  }
) {
  const videoPlayer = playerRef;
  const skipIntroContainer = document.createElement('DIV');
  skipIntroContainer.className = `skip_intro_button`;
  skipIntroContainer.innerHTML = 'SKIP INTRO';
  document
    .getElementsByClassName('video-js')[0]
    .appendChild(skipIntroContainer);

  skipIntroContainer.addEventListener('click', () => {
    const PlayState = document.getElementsByClassName('vjs-playing')[0];
    videoPlayer.pause();
    document
      .getElementsByClassName('video-js')[0]
      .removeChild(skipIntroContainer);
    videoPlayer.currentTime(skipIntroEndTime);
    PlayState ? videoPlayer.play() : videoPlayer.pause();
    const eventPayload = {
      introStartTime: skipIntroStartTime,
      introEndTime: skipIntroEndTime
    };
    analyticsManager.reportEvent('Skip Intro', eventPayload);
  });
}
