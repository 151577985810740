export class Events {
  static readonly DESTROY: string = 'player_destroy';
  static readonly ENDED: string = 'playback_ended';
  static readonly ERROR: string = 'player_error';
  static readonly FULL_SCREEN_CHANGE: string = 'full_screen_changed';
  static readonly LOAD_START: string = 'player_load_start';
  static readonly LOADED_DATA: string = 'player_loaded_data';
  static readonly PAUSE: string = 'player_pause';
  static readonly PLAY: string = 'player_play';
  static readonly PROGRESS: string = 'player_progress';
  static readonly QUALITY_CHANGE: string = 'player_quality_change';
  static readonly READY: string = 'player_ready';
  static readonly RESUME: string = 'player_resume';
  static readonly SEEKED: string = 'player_seeked';
  static readonly SEEKING: string = 'player_seeking';
  static readonly STARTED: string = 'player_started';
  static readonly VOLUME_CHANGE: string = 'player_volume_change';
  static readonly TIME_UPDATE: string = 'player_time_update';
  static readonly META_DATA_LOADED: string = 'meta_data_loaded';
  static readonly DISPOSE: string = 'player_dispose';
  static readonly SUBTITLECHANGE: string = 'subtitleChanged';
  static readonly SKIPINTRO: string = 'skipintro';
  static readonly MUTE: string = 'mute';
}
