import {MixpanelAnalyticsTracker} from './MixpanelAnalyticsTracker';
import {
  InitializeAdsMetadata,
  InitializeVmaxAdsMetadata
} from './MixPanelMetaHelper';
import {MPAdsMetadata} from './MPAdsMetadata';
import {playerEventsConstants} from '../constants/playerConstants';
import {convertSecsToTime, getAffiliate} from '../utils/Utils';

let mixpanelAnalyticsTracker: any;

export class AnalyticsManager {
  // eslint-disable-next-line
  private static _instance?: AnalyticsManager;
  videoPlayer?: any;
  oldSubtitleLanguage?: any;
  newSubtitleLanguage?: any;
  oldAudioLanguage?: any;
  newAudioLanguage?: any;
  oldVideoQuality?: any;
  newVideoQuality?: any;
  checkAudioValApi: any;

  private constructor() {
    mixpanelAnalyticsTracker = new MixpanelAnalyticsTracker();
  }

  public static getInstance() {
    if (!AnalyticsManager._instance) {
      AnalyticsManager._instance = new AnalyticsManager();
    }
    return AnalyticsManager._instance;
  }

  public reportEvent(
    eventName: string,
    eventPlayload: {
      element?: string;
      muteValue?: any;
      newAudioLanguage?: any;
      oldAudioLanguage?: any;
      newVideoQuality?: any;
      oldSubtitleLanguage?: any;
      newSubtitleLanguage?: any;
      oldVideoQuality?: any;
    }
  ) {
    if (!this.videoPlayer) {
      return;
    }
    if (eventName === playerEventsConstants.firstPlay) {
      (this.oldSubtitleLanguage = 'off'), (this.newSubtitleLanguage = 'off');
      eventPlayload = {
        ...eventPlayload,
        oldSubtitleLanguage: this.oldSubtitleLanguage,
        newSubtitleLanguage: this.newSubtitleLanguage
      };
      const defaultAudioLanguage = this.videoPlayer
        .audioTracks()
        .tracks_.filter((track: {enabled: boolean}) => track.enabled === true);
      if (defaultAudioLanguage && defaultAudioLanguage.length > 0) {
        this.newAudioLanguage = defaultAudioLanguage[0].label;
      } else {
        this.newAudioLanguage = 'NA';
      }
      const qualityLevels = this.videoPlayer.qualityLevels();
      this.newVideoQuality = 'Auto';
      if (qualityLevels && qualityLevels.length > 0) {
        this.newVideoQuality =
          qualityLevels[qualityLevels.selectedIndex].height || 'Auto';
      }
    } else if (eventName === playerEventsConstants.subTitleChange) {
      this.newSubtitleLanguage = 'off';
      const subsActive = this.videoPlayer
        .textTracks()
        .tracks_.filter((subs: {mode: string}) => subs.mode === 'showing');
      if (subsActive && subsActive.length > 0) {
        this.newSubtitleLanguage = subsActive[0].label;
      }
      eventPlayload = {
        oldSubtitleLanguage: this.oldSubtitleLanguage,
        newSubtitleLanguage: this.newSubtitleLanguage
      };
      this.oldSubtitleLanguage = this.newSubtitleLanguage;
    } else if (eventName === playerEventsConstants.audioLanguageChange) {
      if (this.oldAudioLanguage === eventPlayload.newAudioLanguage) {
        return;
      }
      if (
        eventPlayload.oldAudioLanguage &&
        eventPlayload.oldAudioLanguage !== 'N/A'
      ) {
        eventPlayload = {
          oldAudioLanguage: eventPlayload.oldAudioLanguage,
          newAudioLanguage:
            eventPlayload.newAudioLanguage || this.oldAudioLanguage
        };
      } else {
        eventPlayload = {
          oldAudioLanguage: this.oldAudioLanguage || 'NA',
          newAudioLanguage:
            eventPlayload.newAudioLanguage || this.oldAudioLanguage
        };
      }
      this.oldAudioLanguage = this.newAudioLanguage;
      this.newAudioLanguage =
        eventPlayload.newAudioLanguage || this.oldAudioLanguage;
    } else if (eventName === playerEventsConstants.qualityChange) {
      if (this.oldVideoQuality === eventPlayload.newVideoQuality) {
        return;
      }
      eventPlayload = {
        oldVideoQuality: this.oldVideoQuality || 'Auto',
        newVideoQuality: eventPlayload.newVideoQuality || this.oldVideoQuality
      };
      this.oldVideoQuality = this.newVideoQuality;
      this.newVideoQuality =
        eventPlayload.newVideoQuality || this.oldVideoQuality;
    }
    mixpanelAnalyticsTracker.ReportEvent(eventName, eventPlayload);
  }

  public setMixpanelData(payload: any) {
    mixpanelAnalyticsTracker.setMixpanelMetaData(payload);
  }

  setMixpanelVideoPlayer = function (videoPlayer: any) {
    mixpanelAnalyticsTracker.setMixpanelVideoPlayer(videoPlayer);
  };

  setClevertapEventPushRef = function (clevertapEventPush: any) {
    mixpanelAnalyticsTracker.setClevertapEventPushRef(clevertapEventPush);
  };

  public jsonInitilize(
    contentMetadata: {
      singlePlayData: {
        assetDetails: any;
        keyOsDetails: any;
        showDetails: any;
        entitlement: {
          isLive: {isTrailer: number};
          isTrailer: number;
        };
        trailerDetails: object;
        singlePlayData: {
          assetDetails: any;
        };
        watchHistoryDetails: any;
      };
      userData: {
        id: {gender: any; age: any};
        gender: any;
        age: any;
      };
      mixpanelDetail: any;
      analyticData: any;
      autoplay: string;
      viewerGender: any;
      viewerAge: any;
      playerVersion: any;
      connectionType: any;
    },
    videoPlayer: any
  ) {
    if (!contentMetadata || !videoPlayer) {
      return;
    }
    this.videoPlayer = videoPlayer;
    const singlePlayData: any = contentMetadata.singlePlayData || {};
    const userData: any = contentMetadata.userData || {};
    const {entitlement: {isTrailer = 0} = {}, trailerDetails = {}} =
      singlePlayData || {};
    const assetDetails = !isTrailer
      ? singlePlayData.assetDetails
      : trailerDetails;
    const contentMetadataObj: any = {};
    if (contentMetadata.mixpanelDetail) {
      contentMetadataObj.mixpanelDetail = contentMetadata.mixpanelDetail;
    }
    if (contentMetadata.analyticData) {
      contentMetadataObj.analyticData = contentMetadata.analyticData;
    }
    if (contentMetadata.singlePlayData) {
      contentMetadataObj.singlePlayData = contentMetadata.singlePlayData;
    }
    contentMetadata.autoplay = 'Yes';
    contentMetadata.viewerGender = (userData && userData.gender) || 'NA';
    contentMetadata.viewerAge = (userData && userData.age) || 'NA';
    contentMetadata.playerVersion =
      ((window as any).convivaSDK &&
        (window as any).convivaSDK.Gi &&
        (window as any).convivaSDK.Gi.version) ||
      '4.5.7';

    // browser Compatibility issue. (firefox has browser Compatibility issue)
    contentMetadata.connectionType = (navigator as any).connection
      ? (navigator as any).connection.effectiveType
      : 'NA';
    contentMetadataObj.affiliate = getAffiliate() || 'NA';
    contentMetadataObj.site = (window as any).location.hostname;

    contentMetadataObj.assetName = assetDetails.original_title;
    if (singlePlayData.entitlement && singlePlayData.entitlement.isLive) {
      contentMetadataObj.streamUrl = singlePlayData.keyOsDetails.video_token;
    } else if (assetDetails.is_drm) {
      contentMetadataObj.streamUrl = assetDetails.video || 'NA';
    } else {
      contentMetadataObj.streamUrl =
        singlePlayData.keyOsDetails && singlePlayData.keyOsDetails.hls_token;
    }
    contentMetadataObj.duration = assetDetails.duration;
    contentMetadataObj.encodedFrameRate = 'NA';
    contentMetadataObj.accessType = 'NA'; // yet to Implement
    contentMetadataObj.contentID = assetDetails.id;
    contentMetadataObj.viewerId = (userData && userData.id) || 'NA';
    contentMetadataObj.viewerGender = (userData && userData.gender) || 'NA';
    contentMetadataObj.viewerAge = (userData && userData.age) || 'NA';
    contentMetadataObj.billing_type = assetDetails.billing_type || 'NA';

    if (
      assetDetails.subtitle_languages &&
      assetDetails.subtitle_languages.length > 0
    ) {
      contentMetadataObj.subtitle = assetDetails.subtitle_languages;
    } else {
      contentMetadataObj.subtitle = 'NA';
    }

    if (assetDetails.genres && assetDetails.genres.length > 0) {
      contentMetadataObj.gener = assetDetails.genres.map(
        (gener: {id: any}) => gener.id
      );
      contentMetadataObj.lotamegener = assetDetails.genres
        .map((gener: {id: any}) => gener.id)
        .join(' ');
    } else {
      contentMetadataObj.gener = 'NA';
    }
    // if (!window.islive) {

    if (
      assetDetails.audio_languages &&
      assetDetails.audio_languages.length > 0
    ) {
      this.checkAudioValApi = assetDetails.audio_languages;
      contentMetadataObj.audioLanguage = assetDetails.audio_languages;
      contentMetadataObj.DisplayLanguage = assetDetails.audio_languages[0];
    } else {
      contentMetadataObj.audioLanguage = 'NA';
      contentMetadataObj.DisplayLanguage = 'NA';
    }
    if (singlePlayData.entitlement && singlePlayData.entitlement.isLive) {
      contentMetadataObj.channel = assetDetails.original_title;

      if (assetDetails.countries && assetDetails.countries.length > 0) {
        contentMetadataObj.lotamecountries = assetDetails.countries.join(' ');
      } else {
        contentMetadataObj.lotamecountries = 'NA';
      }
      if (assetDetails.asset_subtype == 'episode') {
        contentMetadataObj.episodeName =
          assetDetails.original_title || assetDetails.title;
        contentMetadataObj.show =
          assetDetails.showDetails.original_title ||
          assetDetails.showDetails.title;
      } else {
        contentMetadataObj.episodeName = 'NA';
        contentMetadataObj.show = 'NA';
      }
      contentMetadataObj.screen_height = (window as any).screen.availHeight;
      contentMetadataObj.screen_width = (window as any).screen.availWidth;
      contentMetadataObj.originalLanguage =
        (assetDetails.languages && assetDetails.languages[0]) || 'NA';
      contentMetadataObj.lotameSeason = assetDetails.season;
    }
    // for non live content ..
    else {
      contentMetadataObj.episodeName = 'NA';
      contentMetadataObj.category = 'NA';
      if (assetDetails.channels && assetDetails.channels.length) {
        contentMetadataObj.channel = assetDetails.channels.map(
          (channel: {original_title: any}) => channel.original_title
        );
      } else {
        contentMetadataObj.channel = 'NA';
      }
      contentMetadataObj.ContentType = 'NA';
      contentMetadataObj.pubDate = 'NA';
      contentMetadataObj.show =
        assetDetails.original_title || assetDetails.title;
      contentMetadataObj.originalLanguage = 'NA';
      contentMetadataObj.audioLanguage = assetDetails.audio_languages || 'NA';
    }
    contentMetadataObj.originalLanguage = assetDetails.languages[0];
    contentMetadataObj.pubDate = assetDetails.release_date;
    if (singlePlayData.showDetails) {
      contentMetadataObj.show = singlePlayData.showDetails.original_title;
      contentMetadataObj.season = singlePlayData.showDetails.season;
    } else {
      contentMetadataObj.show = 'NA';
      contentMetadataObj.season = 'NA';
    }

    // for mixpanel custom tag only ..

    if (assetDetails.actors && assetDetails.actors.length > 0) {
      contentMetadataObj.Actors = assetDetails.actors;
      contentMetadataObj.character = assetDetails.actors;
    } else {
      contentMetadataObj.Actors = 'N/A';
      contentMetadataObj.character = 'N/A';
    }

    contentMetadataObj.episodeNumber =
      assetDetails.asset_subtype === 'episode'
        ? assetDetails.orderid || 'NA'
        : 'NA';

    // if (assetDetails.video_details && assetDetails.video_details.is_drm) contentMetadataObj.drmVideo = assetDetails.video_details.is_drm;
    contentMetadataObj.drmVideo = !!assetDetails.is_drm;
    contentMetadataObj.playerName = 'Web-Zee5';
    let played_duration = 0;
    if (singlePlayData && singlePlayData.watchHistoryDetails) {
      const {watchHistoryDetails = {}} = singlePlayData || {};
      played_duration = watchHistoryDetails.played_duration
        ? watchHistoryDetails.played_duration
        : 0;
    }
    contentMetadataObj.playerHeadPosition =
      played_duration != 0 ? convertSecsToTime(played_duration) : '00:00:00';

    return contentMetadataObj;
  }

  public AdsInitilization(adEvent: {getAd: () => any}) {
    const adsMetadata: MPAdsMetadata = InitializeAdsMetadata(adEvent);
    mixpanelAnalyticsTracker.setMixpanelAdMetaData(adsMetadata);
  }

  public VmaxAdsInitilization(adEvent: {
    [x: string]: any;
    adTitle: string;
    id: string;
    adClickThrough: string;
    pos: any;
    name: string;
    advertiserMarketer: string;
    advertiserId: any;
    campaignName: any;
  }) {
    const adsMetadata: MPAdsMetadata = InitializeVmaxAdsMetadata(adEvent);
    mixpanelAnalyticsTracker.setMixpanelAdMetaData(adsMetadata);
  }
}
