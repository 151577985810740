export function adjustPinScorecardContainerOnChromecast(add = false) {
  const pinScorecardContainer = document.getElementById('scoreMainContainer');
  const pinScorecardContainerClassList = pinScorecardContainer?.classList;

  // if (pinScorecardContainerClassList && pinScorecardContainerClassList.length) {
  add
    ? pinScorecardContainerClassList?.add('pinScorecardContainerOnChromecast')
    : pinScorecardContainerClassList?.remove(
        'pinScorecardContainerOnChromecast'
      );
  // }
}
