import {playerEventsConstants} from '../constants/playerConstants';

export function showTobaccoAdvisory(
  videoPlayer: {el: () => any},
  tobaccoImgPath: string,
  audioLanguage: string,
  analyticsManager: any
) {
  const tobaccoAdvisoryContainer = document.querySelector('.tobaccoAdvisory');
  const eventPayLoad = {
    healthSpotPlacement: 'Aston Band'
  };
  if (
    tobaccoAdvisoryContainer &&
    tobaccoAdvisoryContainer instanceof HTMLElement
  ) {
    console.log('tobacco-advisory6', tobaccoAdvisoryContainer);
    const tobaccoAdvisoryContainerLanguage =
      tobaccoAdvisoryContainer.getAttribute('data-language');
    if (tobaccoAdvisoryContainerLanguage !== audioLanguage) {
      tobaccoAdvisoryContainer.setAttribute('data-language', audioLanguage);
      const tobaccoAdvisoryIcon = document.querySelector(
        '.tobaccoAdvisoryIcon'
      );
      if (tobaccoAdvisoryIcon instanceof HTMLImageElement) {
        tobaccoAdvisoryIcon.src = tobaccoImgPath;
      }
    }
    if (tobaccoAdvisoryContainer.style.display !== 'block') {
      console.log('tobacco-advisory7', tobaccoAdvisoryContainer.style.display);
      analyticsManager.reportEvent(
        playerEventsConstants.healthSpotViewed,
        eventPayLoad
      );
      tobaccoAdvisoryContainer.style.display = 'block';
    }
  } else {
    const tobaccoAdvisory = document.createElement('div');
    tobaccoAdvisory.setAttribute('class', 'tobaccoAdvisory');
    tobaccoAdvisory.setAttribute('data-language', audioLanguage);
    tobaccoAdvisory.style.display = 'block';
    const advisoryImg = document.createElement('img');
    advisoryImg.setAttribute('class', 'tobaccoAdvisoryIcon');
    advisoryImg.src = tobaccoImgPath;
    tobaccoAdvisory.appendChild(advisoryImg);
    const videoEL = videoPlayer && videoPlayer.el();
    videoEL && videoEL.appendChild(tobaccoAdvisory);
    console.log('tobacco-advisory8', videoPlayer);
    analyticsManager.reportEvent(
      playerEventsConstants.healthSpotViewed,
      eventPayLoad
    );
  }
}

export function removeTobaccoAdvisory() {
  const tobaccoAdvisoryContainer = document.querySelector('.tobaccoAdvisory');
  if (
    tobaccoAdvisoryContainer &&
    tobaccoAdvisoryContainer instanceof HTMLElement
  ) {
    console.log('tobacco-advisory9', tobaccoAdvisoryContainer);
    tobaccoAdvisoryContainer.style.display = 'none';
  }
}
