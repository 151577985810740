// import './Skipintro.css';

import {destroyUpNextCarousel} from '../utils/playerEventsHelper';

export function watchCredits(
  videoPlayer: any,
  carouselStatus: boolean,
  toggleWatchTrailerButton: (arg0: {disable: boolean}) => void,
  analyticsManager: {reportEvent: (arg0: string) => void}
) {
  const watchCreditsContainer = document.createElement('DIV');
  watchCreditsContainer.className = `watch_credits_button ${
    carouselStatus ? 'carouselActive' : ''
  }`;
  watchCreditsContainer.id = 'watchCreditsId';
  watchCreditsContainer.innerHTML = 'WATCH CREDITS';
  document
    .getElementsByClassName('video-js')[0]
    .appendChild(watchCreditsContainer);

  watchCreditsContainer.addEventListener('click', () => {
    document
      .getElementsByClassName('video-js')[0]
      .removeChild(watchCreditsContainer);
    destroyUpNextCarousel('continueCredits', videoPlayer);
    analyticsManager.reportEvent('Watch Credits');
    if (toggleWatchTrailerButton) {
      toggleWatchTrailerButton({disable: false});
    }
  });
}
