export class Error {
  code: ErrorCode;
  severity: ErrorSeverity;
  category: ErrorCategory;
  message: string;
  errorMessage: string;

  constructor(
    code: ErrorCode,
    category: ErrorCategory,
    severity: ErrorSeverity,
    message: string
  ) {
    this.code = code;
    this.category = category;
    this.severity = severity;
    this.message = message;
    this.errorMessage = '';
  }
}

export enum ErrorSeverity {
  RECOVERABLE,
  CRITICAL
}

export enum ErrorCategory {
  // Error from network stack
  NETWORK,
  // Error parsing text stream
  TEXT,
  // Error parsing or processing audio video stream
  MEDIA,
  // Error parsing manifest file
  MANIFEST,
  // Errors related to streaming.
  STREAMING,
  // Errors related to DRM.
  DRM,
  // Miscellaneous errors from the player.
  PLAYER,
  // Errors related to cast.
  CAST,
  // Errors related to ad insertion.
  ADS,
  // Error Related to License Errors
  MEDIAKEYSYSTEMS
}

export enum ErrorCode {
  // A network request was made using an unsupported URI scheme
  UNSUPPORTED_SCHEME = 1000,
  // An HTTP network request returned an HTTP status that indicated a failure
  BAD_HTTP_STATUS = 1001,
  // 	An HTTP network request failed with an error, but not from the server
  HTTP_ERROR = 1002,
  // A network request timed out.
  TIMEOUT = 1003,
  // A network request was made with a malformed data URI.
  MALFORMED_DATA_URI = 1004,
  // A request filter threw an error.
  REQUEST_FILTER_ERROR = 1005,
  // The text parser failed to parse a text stream due to an invalid header.
  INVALID_TEXT_HEADER = 2000,
  // The text parser failed to parse a text stream due to an invalid cue.
  INVALID_TEXT_CUE = 2001,

  MEDIA_ERR_SRC_NOT_SUPPORTED = 3000,

  NETWORK = 3001,

  DECODER = 3002,

  ABORTED = 3003,

  CUSTOM = 3004,

  //There was a problem requesting ads from the server.
  FAILED_TO_REQUEST_ADS = 9000,

  MEDIAKEYSYSTEMS = 3005
}
