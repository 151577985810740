import {MediaAds} from '../MediaAds';

import 'videojs-contrib-ads';
import 'videojs-ima';
import EventEmitter from 'events';

import {ConvivaAnalyticsTracker} from '../../analytics/ConvivaAnalyticsTracker';
import {IMAConfig} from '../../configs/IMAConfig';
import {
  Error,
  ErrorCategory,
  ErrorCode,
  ErrorSeverity
} from '../../error/Error';
import {AdEvents} from '../../events/AdEvents';
import {AdsManager} from '../AdsManager';
import {mergeObjects} from '../../utils/Utils';

export class GoogleIMA extends EventEmitter implements MediaAds {
  videoPlayer: any;
  isAdBreak = false;
  videoElement: Element | null | undefined;
  adsLoader: any;
  adRequest: any;
  adsManager: any;
  adConfig: any;
  countdownTimer: any;
  clickTrackingElement: any;
  currentAd: any;
  manager: any;
  moatEnabled: boolean;
  convivaAnalyticsTracker: any;
  adsUtilsData: any;

  // Constructor function
  constructor(
    videoElement: Element | null | undefined,
    config: IMAConfig,
    player: any,
    manager: AdsManager,
    adsUtilsData: any
  ) {
    super();
    this.manager = manager;
    this.videoPlayer = player;
    this.adConfig = config;
    this.videoElement = videoElement;
    this.moatEnabled = false;
    this.initialization();
    this.convivaAnalyticsTracker = new ConvivaAnalyticsTracker();
    this.adsUtilsData = adsUtilsData;
  }

  // Initializing google IMA SDK
  initialization() {
    let maxRedirects: any;
    if (window.isLive) {
      maxRedirects =
        this.adsUtilsData?.ads_vast_max_redirect_live > 0
          ? this.adsUtilsData?.ads_vast_max_redirect_live
          : '';
    } else {
      maxRedirects =
        this.adsUtilsData?.ads_vast_max_redirect_vod > 0
          ? this.adsUtilsData?.ads_vast_max_redirect_vod
          : '';
    }
    const imaDefaultSettings = {
      vastLoadTimeout: 500,
      numRedirects: 4,
      adsRenderingSettings: {
        loadVideoTimeout: 4000,
        bitrate: -1,
        enablePreloading: false
      },
      disableCustomPlaybackForIOS10Plus: true,
      disableAdControls: true,
      preventLateAdStart: true,
      showCountdown: false,
      disableFlashAds: true,
      debug: false,
      timeout: 8000,
      contribAdsSettings: {
        prerollTimeout: 8000
      }
    };
    let imaOptions = {
      adsResponse: this.adConfig.adResponse ? this.adConfig.adResponse : '',
      adTagUrl: this.adConfig.adTagUrl ? this.adConfig.adTagUrl : ''
    };
    const imaSettings = mergeObjects(
      imaDefaultSettings,
      this.adConfig.imaSettingsFromS3
    );
    imaOptions = mergeObjects(imaOptions, imaSettings);
    imaOptions.numRedirects = maxRedirects;
    this.videoPlayer.ima(imaOptions);
    this.videoPlayer.ima.initializeAdDisplayContainer();
    this.videoPlayer.on('ads-manager', this.onAdsManagerLoaded.bind(this));
    this.videoPlayer.on('ads-loader', this.onAdsLoaderLoaded.bind(this));
    this.clickTrackingElement = document.getElementById('zee-ad-container');
    this.clickTrackingElement.addEventListener(
      'click',
      this.onAdUiClickListener.bind(this)
    );
  }

  // Instantiate the AdManager from adsLoader response
  onAdsManagerLoaded(response: any) {
    this.adsManager = response.adsManager;
    this.adsManager.addEventListener(
      (google as any).ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdErrorListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      this.onContentPauseRequested.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      this.onContentResumeRequested.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.LOADED,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.ALL_ADS_COMPLETED,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.STARTED,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.COMPLETE,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.FIRST_QUARTILE,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.MIDPOINT,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.THIRD_QUARTILE,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.PAUSED,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.RESUMED,
      this.onAdResume.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.SKIPPED,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.DURATION_CHANGE,
      this.onAdEventListener.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.CLICK,
      this.onAdClicked.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.AD_PROGRESS,
      this.onAdProgress.bind(this)
    );
    this.adsManager.addEventListener(
      (google as any).ima.AdEvent.Type.LOG,
      this.onAdLogsListener.bind(this)
    );
  }

  //Fired when a non-fatal error is encountered
  onAdLogsListener(event: any) {
    if (event.type == (google as any).ima.AdEvent.Type.LOG) {
      const adData = event.getAdData();
      if (adData['adError']) {
        const message = adData['adError'].getMessage();
        const error = new Error(
          ErrorCode.FAILED_TO_REQUEST_ADS,
          ErrorCategory.ADS,
          ErrorSeverity.CRITICAL,
          message
        );
        error.errorMessage = error.message;
        this.emit(AdEvents.AD_ERROR, error);
      }
    }
  }

  // ads loaded loaded completed listener
  onAdsLoaderLoaded(response: any) {
    console.log('Ad loader loaded');
    this.adsLoader = response.adsLoader;
    this.adsLoader.addEventListener(
      (google as any).ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdErrorListener.bind(this),
      false
    );
    window.adsLoader = this.adsLoader;
  }

  // Handle ad loading errors
  onAdErrorListener(event: any) {
    // console.log(event.getError())
    const adError = event.getError();
    // emits ad load related errors in type is AD_LOAD
    if ((google as any).ima.AdError.Type.AD_LOAD == adError.getType()) {
      const message = adError.getMessage();
      const error = new Error(
        ErrorCode.FAILED_TO_REQUEST_ADS,
        ErrorCategory.ADS,
        ErrorSeverity.CRITICAL,
        message
      );
      error.errorMessage = error.message;
      this.emit(AdEvents.AD_ERROR, error);
    } else {
      const error = {
        errorMessage: adError.getMessage()
      };
      this.emit(AdEvents.AD_ERROR, error);
    }
    this.destroy();
  }

  // code for listening ad events
  private onAdEventListener(adEvent: any) {
    const ad = adEvent.getAd();
    switch (adEvent.type) {
      case (google as any).ima.AdEvent.Type.LOADED:
        // moat validation start
        try {
          if (
            Object.prototype.hasOwnProperty.call(
              ad.getTraffickingParameters(),
              'moatenable'
            ) &&
            ad.getTraffickingParameters().moatenable
          ) {
            this.moatEnabled = true;
            const ids = {
              partnerCode: 'zeedfpvideo717778523215',
              viewMode: (google as any).ima.ViewMode.NORMAL
            };
            this.initMoatTracking(
              this.adsManager,
              ids,
              this.clickTrackingElement
            );
          }
        } catch (error) {
          console.log('Moat Validation');
        }
        // moat validation ends
        this.emit(AdEvents.AD_LOADED, adEvent);
        break;
      case (google as any).ima.AdEvent.Type.STARTED:
        this.currentAd = adEvent.getAd();
        this.emit(AdEvents.AD_STARTED, adEvent);
        this.isAdBreak = true;
        this.onAdBreakStarted();
        this.onAdUiOverlayCompanionBanner();
        break;
      case (google as any).ima.AdEvent.Type.FIRST_QUARTILE:
        this.emit(AdEvents.FIRST_QUARTILE, ad);
        break;
      case (google as any).ima.AdEvent.Type.MIDPOINT:
        this.emit(AdEvents.MIDPOINT, ad);
        break;
      case (google as any).ima.AdEvent.Type.THIRD_QUARTILE:
        this.emit(AdEvents.THIRD_QUARTILE, ad);
        break;
      case (google as any).ima.AdEvent.Type.COMPLETE:
        this.moatEnabled = false;
        this.emit(AdEvents.COMPLETE, ad);
        this.isAdBreak = false;
        break;
      case (google as any).ima.AdEvent.Type.ALL_ADS_COMPLETED:
        this.emit(AdEvents.ALL_ADS_COMPLETED, ad);
        break;
      case (google as any).ima.AdEvent.Type.SKIPPED:
        this.moatEnabled = false;
        this.emit(AdEvents.SKIPPED, ad);
        break;
      case (google as any).ima.AdEvent.Type.PAUSED:
        this.clickTrackingElement.style.pointerEvents = 'auto';
        this.emit(AdEvents.AD_PAUSE, ad);
        break;
    }
  }

  initMoatTracking(b: any, h: {partnerCode: string; viewMode: any}, d: any) {
    if (!1 === Object.prototype.hasOwnProperty.call(h, 'partnerCode'))
      return !1;
    const k = document.createElement('script');
    d =
      d ||
      (b && (typeof b.O !== 'undefined' ? b.O.parentNode : document.body)) ||
      document.body;
    const f: any = [];

    const c = {
      adsManager: b,
      ids: h,
      imaSDK: !0,
      events: [],
      dispatchEvent(this: any, a: any) {
        const b = this.sendEvent;

        const c = this.events;
        b ? (c && (c.push(a), (a = c)), b(a)) : c.push(a);
      }
    };

    const p = {
      complete: 'AdVideoComplete',
      firstquartile: 'AdVideoFirstQuartile',
      impression: 'AdImpression',
      loaded: 'AdLoaded',
      midpoint: 'AdVideoMidpoint',
      pause: 'AdPaused',
      skip: 'AdSkipped',
      start: 'AdVideoStart',
      thirdquartile: 'AdVideoThirdQuartile',
      volumeChange: 'AdVolumeChange'
    };
    let e: any = '';
    if (google && (google as any).ima && b) {
      e = `_moatApi${Math.floor(1e8 * Math.random())}`;

      let l;
      for (l in (google as any).ima.AdEvent.Type) {
        const n = function (a: any) {
          if ((c as any).sendEvent) {
            for (a = f.length - 1; a >= 0; a--)
              b.removeEventListener(f[a].type, f[a].func);
            (c as any).sendEvent(c.events);
          } else
            (c as any).events.push({
              type: (p as any)[a.type] || a.type,
              adVolume: b.getVolume()
            });
        };
        b.addEventListener((google as any).ima.AdEvent.Type[l], n);
        f.push({type: (google as any).ima.AdEvent.Type[l], func: n});
      }
    }

    let g;

    let m;
    try {
      (g = d.ownerDocument), (m = g.defaultView || g.parentWindow);
    } catch (a) {
      (g = document), (m = window);
    }
    m[e] = c;
    k.type = 'text/javascript';
    d && d.appendChild(k);
    k.src = `https://z.moatads.com/${h.partnerCode}/moatvideo.js#${e}`;
    return c;
  }

  // click tracking element click listener for resume paused ads
  onAdUiClickListener() {
    // // console.log("AdUi Clicked")
    this.adsManager.resume();
    this.clickTrackingElement.style.pointerEvents = 'none';
  }

  // add Overlay Companion ad on video ads
  onAdUiOverlayCompanionBanner() {
    const companionAds = this.currentAd.getCompanionAds(320, 50);
    if (companionAds.length > 0) {
      const companionAd = companionAds[0];
      // Get HTML content from the companion ad.
      const content = companionAd.getContent();
      const div: any = document.getElementById('zee-ad-companion');
      if (div) div.style.display = 'flex';
      // const companionAdContainer: any = document.querySelector('.adContainer');
      // companionAdContainer.style.display = 'none';
      div.innerHTML = content;
    } else {
      const div: any = document.getElementById('zee-ad-companion');
      div.style.display = 'none';
    }
  }

  // called when ad break started
  onAdBreakStarted() {
    this.countdownTimer = setInterval(() => {
      //const remainingTime = this.adsManager.getRemainingTime();
      //this.countdownUi.innerHTML = 'Remaining Time: ' + parseInt(remainingTime);
    }, 300);
  }

  // Fired when content should be paused
  onContentPauseRequested() {
    console.log('Content pause requested');
  }
  // Fired when content should be resumed
  onContentResumeRequested(ad: any) {
    console.log('Content resume requested');
    clearInterval(this.countdownTimer);
    const currentAd = ad.getAd();
    const podInfo = currentAd.getAdPodInfo();
    if (
      podInfo &&
      podInfo.getPodIndex &&
      podInfo.getPodIndex() == 0 &&
      podInfo.getAdPosition() == podInfo.getTotalAds()
    ) {
      this.emit(AdEvents.PRE_ROLL_COMPLETED, podInfo);
    }
  }

  // Fired when the ad is clicked
  onAdClicked(adEvent: any) {
    this.emit(AdEvents.AD_CLICKED, adEvent);
    this.emit(AdEvents.AD_PAUSE, adEvent);
  }

  onAdResume() {
    // this.emit(AdEvents.adPaused, adEvent)
  }

  //Fired when the ad's current time value changes
  onAdProgress(event: any) {
    this.emit(AdEvents.AD_PROGRESS, event.getAdData());
    const remainingTime = this.adsManager.getRemainingTime();
    const duration =
      this.currentAd && this.currentAd.getDuration()
        ? this.currentAd.getDuration()
        : 0;
    let currentTime = duration - remainingTime;
    currentTime = currentTime > 0 ? currentTime : 0;

    let totalAds = 0;
    let adPosition;
    if (this.currentAd && this.currentAd.getAdPodInfo()) {
      adPosition = this.currentAd.getAdPodInfo().getAdPosition();
      totalAds = this.currentAd.getAdPodInfo().getTotalAds();
    }
    this.manager.onAdPlayheadUpdated(
      currentTime,
      remainingTime,
      duration,
      adPosition,
      totalAds
    );
  }

  // Pauses the current ad that is playing
  pause(): void {
    if (this.adsManager) {
      this.adsManager.pause();
    }
  }

  // Resumes the current ad that is loaded and paused
  resume(): void {
    if (this.adsManager) {
      this.adsManager.resume();
    }
  }

  // code will destroys the component
  destroy(): void {
    if (this.adsManager) {
      this.clickTrackingElement.style.pointerEvents = 'none';
      this.adsManager.destroy();
      clearInterval(this.countdownTimer);
    }
  }
}
