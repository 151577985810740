/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {DRMConfig} from '../configs/DRMConfig';
import {base64EncodeUint8Array, base64DecodeUint8Array} from '../utils/Utils';

export class KeySystem {
  static readonly CLEARKEY: string = 'org.w3.clearkey';
  static readonly FAIRPLAY: string = 'com.apple.fps.1_0';
  static readonly PLAYREADY: string = 'com.microsoft.playready';
  static readonly WIDEVINE: string = 'com.widevine.alpha';

  // function will return configurations for DRM providers
  getConfigs(config: DRMConfig): any {
    switch (config.drm) {
      case KeySystem.WIDEVINE:
        return this.generateWidevineConfigs(config);
      case KeySystem.PLAYREADY:
        return this.generatePlayReadyConfig(config);
      case KeySystem.FAIRPLAY:
        if (this.isLicenseServerNagra(config)) {
          return this.generateFairPlayConfigsNagra(config);
        }
        return this.generateFairPlayConfigs(config);
    }
  }

  private isLicenseServerNagra(config: any) {
    return (
      config && config?.licenseHeaders.get('customdata').includes('Nagra_')
    );
  }

  // code will generate configuration for widevine
  private generateWidevineConfigs(config: DRMConfig): any {
    const map = new Map<string, any>();
    map.set(config.drm, {
      certificateUri: config.certificateUri,
      url: config.licenseUri,
      licenseHeaders: Object.fromEntries(config.licenseHeaders!),
      certificateHeaders: Object.fromEntries(config.certificateHeaders!)
    });
    return Object.fromEntries(map);
  }

  // code will generate PlayReady for IE11 (Windows 8.1+) configurations
  private generatePlayReadyConfig(config: DRMConfig): any {
    const map = new Map<string, any>();
    map.set(config.drm, {
      certificateUri: config.certificateUri,
      certificateHeaders: Object.fromEntries(config.certificateHeaders!),
      licenseUri: config.licenseUri,
      licenseHeaders: Object.fromEntries(config.licenseHeaders!)
    });
    return Object.fromEntries(map);
  }

  // code will generate Fairplay configurations for safari
  private generateFairPlayConfigs(config: DRMConfig): any {
    const map = new Map<string, any>();
    map.set(config.drm, {
      certificateUri: config.certificateUri,
      // function returns content id
      getContentId: (emeOptions: any, initData: any) => {
        // for downgrade contrib-eme 3.10.1
        const contentId = this.arrayToString(initData);
        if (contentId.indexOf('skd://') > -1)
          return contentId.split('skd://')[1];
      },
      // request for license key
      getLicense: (
        emeOptions: any,
        contentId: any,
        keyMessage: any,
        callback: any
      ) => {
        const data =
          'spc=' + base64EncodeUint8Array(keyMessage) + '&assetId=' + contentId;
        const headers = Object.fromEntries(config.licenseHeaders!);
        this.loadLicenseUrl(config.licenseUri, data, headers, callback);
      }
    });
    return Object.fromEntries(map);
  }

  // ----------------------------------------------------------------Nagra------------------------------------------------
  private generateFairPlayConfigsNagra(config: DRMConfig): any {
    const map = new Map<string, any>();
    map.set(config.drm, {
      certificateUri: config.certificateUri,
      // function returns content id
      getContentId: (emeOptions: any, initData: any) => {
        // for downgrade contrib-eme 3.10.1
        const contentId = this.arrayToString(initData);
        if (contentId.indexOf('skd://') > -1)
          return contentId.split('skd://')[1];
      },
      // request for license key
      getLicense: (
        emeOptions: any,
        contentId: any,
        keyMessage: any,
        callback: any
      ) => {
        const data = keyMessage;
        const headers = Object.fromEntries(config.licenseHeaders!);
        headers['Content-type'] = 'application/octet-stream';
        this.loadLicenseUrlNagra(config.licenseUri, data, headers, callback);
      }
    });
    return Object.fromEntries(map);
  }

  // loading license keys
  private loadLicenseUrlNagra(
    licenseUri: string,
    data: any,
    headers: any,
    callback: any
  ) {
    fetch(licenseUri, {method: 'POST', headers: headers, body: data})
      .then((response) => {
        if (response.status !== 200) return;
        response.blob().then((result) => {
          const reader = new FileReader();
          reader.readAsBinaryString(result);
          reader.onloadend = function () {
            const base64data = reader.result as string;
            const license = window.btoa(base64data);
            callback(null, base64DecodeUint8Array(license));
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----------------------------------------------------------------Nagra------------------------------------------------

  private arrayToString = (a: {buffer: Iterable<number>}) => {
    const b = new Uint16Array(a.buffer);
    return String.fromCharCode.apply(null, b as any);
  };
  // loading license keys
  private loadLicenseUrl(
    licenseUri: string,
    data: any,
    headers: any,
    callback: any
  ) {
    fetch(licenseUri, {method: 'POST', headers: headers, body: data})
      .then((response) => {
        if (response.status !== 200) return;
        response.text().then((result) => {
          callback(null, base64DecodeUint8Array(result));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
