import {CastHelper} from './CastHelper';
import {AdsManager} from '../advertisement/AdsManager';
import {AnalyticsManager} from '../analytics/AnalyticsManager';
import {castEventsConstants} from '../constants/playerConstants';

export class CastSender {
  adsManager?: AdsManager;
  context?: cast.framework.CastContext;
  remotePlayer?: cast.framework.RemotePlayer | undefined;
  remotePlayerController?: cast.framework.RemotePlayerController;
  castHelper?: any;
  castSession?: any;
  analyticsManager?: AnalyticsManager;
  videoPlayer: any;

  constructor(videoPlayer: any, adsManager: AdsManager) {
    this.videoPlayer = videoPlayer;
    this.adsManager = adsManager;
    this.analyticsManager = AnalyticsManager.getInstance();
    if (window.isCastAvailable) {
      this.initializeCastApi();
    }
  }

  // code will initialize the cast apis
  private initializeCastApi() {
    cast &&
      cast.framework &&
      cast.framework.setLoggerLevel &&
      cast.framework.setLoggerLevel(cast.framework.LoggerLevel.DEBUG);
    this.context =
      cast &&
      cast.framework &&
      cast.framework.CastContext &&
      cast.framework.CastContext.getInstance &&
      cast.framework.CastContext.getInstance();
    this.remotePlayer =
      cast &&
      cast.framework &&
      cast.framework.RemotePlayer &&
      new cast.framework.RemotePlayer();
    if (this.remotePlayer) {
      this.remotePlayerController =
        cast?.framework?.RemotePlayerController &&
        new cast.framework.RemotePlayerController(this.remotePlayer);
      this.context.addEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        this.initializeCastSession.bind(this)
      );
      this.context.addEventListener(
        cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        this.onSessionStateChange.bind(this)
      );
      this.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
        this.onMediaLoadChangeListener.bind(this)
      );
      this.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        this.onConnectedChangeListener.bind(this)
      );
    }
  }

  initializeCastSession({castState = ''}) {
    if (this.remotePlayer && this.remotePlayerController) {
      this.castHelper = new CastHelper(
        this.videoPlayer,
        this.remotePlayer,
        this.remotePlayerController
      );
    }
    if (castState === 'CONNECTED') {
      this.adsManager?.destroy();
      const adContainer = document.querySelector('#video-tag_ima-ad-container');
      const videoEL = this.videoPlayer && this.videoPlayer.el();
      if (adContainer) {
        videoEL.removeChild(adContainer);
      }
      const customData = this.castHelper.getChromecastCustomData(
        this.videoPlayer
      );
      this.castSession =
        cast.framework.CastContext.getInstance().getCurrentSession();
      this.castSession.addEventListener(
        cast.framework.SessionEventType.ACTIVE_INPUT_STATE_CHANGED,
        this.onActiveInputStateChange
      );
      this.castSession.addEventListener(
        cast.framework.SessionEventType.APPLICATION_METADATA_CHANGED,
        this.onMetaDataChange
      );
      this.castSession.addEventListener(
        cast.framework.SessionEventType.APPLICATION_STATUS_CHANGED,
        this.onApplicationStatusChange
      );
      if (chrome && chrome.cast && chrome.cast.media) {
        const mediaInfo = new chrome.cast.media.MediaInfo();
        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        mediaInfo.customData = customData;
        this.videoPlayer.pause();
        request.currentTime = this.videoPlayer.currentTime();
        this.castSession &&
          this.castSession.loadMedia(request).then(
            () => {
              this.castHelper && this.castHelper.enableCastMode();
              this.analyticsManager?.reportEvent(
                castEventsConstants.CAST_STARTED,
                {}
              );
            },
            (errorCode: any) => {
              console.log('Cast Failed due to : ', errorCode);
              this.castHelper && this.castHelper.castLoadMediaFailed();
              this.analyticsManager?.reportEvent(
                castEventsConstants.CAST_FAILED,
                errorCode
              );
            }
          );
      }
    }
  }

  // session state change listener function
  private onSessionStateChange(event: any) {
    switch (event.sessionState) {
      case cast.framework.SessionState.SESSION_STARTED:
        console.log('CastContext: CastSession started');
        break;
      case cast.framework.SessionState.SESSION_RESUMED:
        console.log('CastContext: CastSession resumed');
        break;
      case cast.framework.SessionState.SESSION_ENDED:
        console.log('CastContext: CastSession disconnected');
        break;
    }
  }

  // media info change listener function
  private onMediaLoadChangeListener() {
    const session =
      cast.framework.CastContext.getInstance().getCurrentSession();
    if (session && this.remotePlayer && this.remotePlayerController) {
      this.remotePlayer.currentTime = this.videoPlayer.currentTime();
      this.remotePlayerController.seek();
    } else {
      return;
    }
  }

  // code will listen for disconnection
  private onConnectedChangeListener() {
    if (!(this.remotePlayer && this.remotePlayer.isConnected)) {
      this.castSession && this.castSession.endSession(true);
      window['isCastConnected'] = false;
      this.castHelper.stopCasting();
      this.analyticsManager?.reportEvent(castEventsConstants.CAST_ENDED, {});
      console.log('RemotePlayerController: Player disconnected');
    }
  }

  private onActiveInputStateChange() {
    console.log('Actvity input state changed');
  }

  private onMetaDataChange() {
    console.log('application metadata changed');
  }

  private onApplicationStatusChange() {
    console.log('application status changed');
  }
}
