export enum TextTrackKind {
  subtitle,
  caption
}
export interface TextTrack {
  mode: string;
  src: string;
  label: string;
  srclang: string;
  kind: TextTrackKind;
}
