import {AssetDetails} from '../configs/SpApiResponse';

export function addWaterMark(videoPlayer: {el: () => any}) {
  const watermarkContainer = document.createElement('div');
  watermarkContainer.setAttribute('class', 'watermarkContainer');

  const zee5Logo = document.createElement('img');
  zee5Logo.setAttribute('class', 'watermarkIcon');
  zee5Logo.src =
    'https://stcf-prod.zee5.com/prod/zee5Videojs/resources/images/ZEE5_Watermark_Logo.svg';
  watermarkContainer.appendChild(zee5Logo);

  const videoEL = videoPlayer && videoPlayer.el();
  videoEL && videoEL.appendChild(watermarkContainer);
}

export function fetchConcurrentUsers(
  playableData: AssetDetails,
  playerConfig: any
) {
  const showInfo = {
    showId: playableData.id,
    country: playerConfig.country
  };
  playerConfig.getConcurrentUsers(showInfo).then((data: {formatted: any}) => {
    const liveUsers = document.querySelector('.liveUsers');
    if (liveUsers && data && data.formatted) {
      updateConcurrentUsers(data.formatted);
    }  else if (data && data.formatted) {
      addConcurrentUsers(data.formatted);
    }
  });
}

function addConcurrentUsers(concurrentUsers: any) {
  const liveUsersContainer = document.querySelector('.liveUsersContainer');
  const watermarkContainer = document.querySelector('.watermarkContainer');
  if (!liveUsersContainer) {
    const liveUsersContainer = document.createElement('div');
    liveUsersContainer.setAttribute('class', 'liveUsersContainer');

    const liveUsers = document.createElement('span');
    liveUsers.setAttribute('class', 'liveUsers');

    if (concurrentUsers && concurrentUsers.trim() !== '') {
      const visibilityOnImg = document.createElement('img');
      visibilityOnImg.setAttribute('class', 'visibilityImg');
      visibilityOnImg.src =
        'https://stcf-prod.zee5.com/prod/zee5Videojs/resources/images/VisibilityOn.svg';
      liveUsersContainer.append(visibilityOnImg);
      liveUsers.innerHTML = `${concurrentUsers} LIVE`;
    } else {
      liveUsers.innerHTML = `LIVE`;
    }
    liveUsersContainer.append(liveUsers);
    if (watermarkContainer) {
      watermarkContainer.appendChild(liveUsersContainer);
    }
  }
}

function updateConcurrentUsers(concurrentUsers: any) {
  const liveUsers = document.querySelector('.liveUsers');
  if (liveUsers) {
    liveUsers.innerHTML = `${concurrentUsers} LIVE`;
  }
}
