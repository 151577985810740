import EventEmitter from 'events';

import {DAIConfig} from '../../configs/DAIConfig';
import {Media} from '../../configs/Media';
import {
  Error,
  ErrorSeverity,
  ErrorCategory,
  ErrorCode
} from '../../error/Error';
import {AdEvents} from '../../events/AdEvents';
import {getEncodedUri} from '../../utils/Utils';
import {AdsManager} from '../AdsManager';
import {MediaAds} from '../MediaAds';

declare global {
  let google: any;
}

export class GoogleDAI extends EventEmitter implements MediaAds {
  streamManager: any;
  mediaSource: Media | undefined;
  videoPlayer: any;
  videoElement: Element | null | undefined;
  adsUiElement: any;
  adConfig?: DAIConfig;
  isAdBreak: boolean;
  manager: any;
  DAIParams: any;
  // constructor function accept player and config
  constructor(
    player: any,
    videoElement: Element | null | undefined,
    config: Media | undefined,
    manager: AdsManager
  ) {
    super();
    this.manager = manager;
    this.videoElement = videoElement;
    this.videoPlayer = player;
    this.mediaSource = config;
    this.isAdBreak = false;
    this.adConfig = this.mediaSource?.advertising?.daiSettings;
    this.DAIParams = this.mediaSource?.cust_params;
    this.adsUiElement = document.getElementById('ad-container')!;
    this.initializeStreamManager();
  }

  // initialize stream manager
  private initializeStreamManager() {
    this.streamManager = new (google as any).ima.dai.api.StreamManager(
      this.videoElement,
      this.adsUiElement
    );
    this.registerEvents();
    if (this.adConfig && this.adConfig.isLiveStream && this.adConfig.assetKey)
      this.requestLiveStream(
        this.adConfig.assetKey,
        this.adConfig.authToken || null
      );
    else
      this.requestVODStream(
        this.adConfig?.contentSourceId,
        this.adConfig?.videoId,
        this.adConfig?.authToken
      );
  }

  // registering all stream related events
  private registerEvents() {
    this.streamManager.addEventListener(
      [
        (google as any).ima.dai.api.StreamEvent.Type.LOADED,
        (google as any).ima.dai.api.StreamEvent.Type.ERROR,
        (google as any).ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
        (google as any).ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
        (google as any).ima.dai.api.StreamEvent.Type.COMPLETE,
        (google as any).ima.dai.api.StreamEvent.Type.FIRST_QUARTILE,
        (google as any).ima.dai.api.StreamEvent.Type.MIDPOINT,
        (google as any).ima.dai.api.StreamEvent.Type.STREAM_INITIALIZED,
        (google as any).ima.dai.api.StreamEvent.Type.THIRD_QUARTILE,
        (google as any).ima.dai.api.StreamEvent.Type.SKIPPABLE_STATE_CHANGED,
        (google as any).ima.dai.api.StreamEvent.Type.SKIPPED,
        (google as any).ima.dai.api.StreamEvent.Type.VIDEO_CLICKED,
        (google as any).ima.dai.api.StreamEvent.Type.PAUSED,
        (google as any).ima.dai.api.StreamEvent.Type.RESUMED,
        (google as any).ima.dai.api.StreamEvent.Type.STARTED,
        (google as any).ima.dai.api.StreamEvent.Type.CLICK
      ],
      this.onStreamEvent.bind(this),
      false
    );

    this.streamManager.addEventListener(
      (google as any).ima.dai.api.StreamEvent.Type.AD_PROGRESS,
      this.onAdProgress.bind(this)
    );
    this.videoPlayer.on('play', this.onMetaDataChangeListener.bind(this));
  }

  // request video on demand streams
  private requestVODStream(
    cmsId: string,
    videoId: string,
    authToken: string | null
  ) {
    const streamRequest = new (google as any).ima.dai.api.VODStreamRequest();
    streamRequest.contentSourceId = cmsId;
    streamRequest.videoId = videoId;
    streamRequest.authToken = authToken;
    this.streamManager.requestStream(streamRequest);
  }

  // request live stream
  private requestLiveStream(assetKey: string, authToken: string | null) {
    const streamRequest = new (google as any).ima.dai.api.LiveStreamRequest();
    streamRequest.assetKey = assetKey;
    streamRequest.authToken = authToken || '';
    streamRequest.adTagParameters = {
      ppid: this.DAIParams.ppid,
      vid: this.DAIParams.vid,
      cust_params: getEncodedUri(this.DAIParams.cust_params)
    };
    this.streamManager.requestStream(streamRequest);
  }

  // extracting ID3 metara data and feeding to stream manager
  private onMetaDataChangeListener() {
    this.videoPlayer.textTracks().on('addtrack', (e: any) => {
      const track = e.track;
      if (track.kind === 'metadata') {
        track.on('cuechange', () => {
          const elemTrack = track.activeCues[0];
          if (elemTrack && elemTrack.value.data) {
            const metadata: any = {};
            metadata[elemTrack.value.key] = elemTrack.value.data;
            metadata['duration'] = Infinity;
            this.streamManager.onTimedMetadata(metadata);
          }
        });
      }
    });
  }

  // stream event listener function
  onStreamEvent(event: any) {
    switch (event.type) {
      case (google as any).ima.dai.api.StreamEvent.Type.LOADED:
        {
          this.emit(AdEvents.LOADED, event);
          const streamURL = event.getStreamData().url;
          this.emit(AdEvents.STREAM_LOADED, streamURL);
        }
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.STARTED:
        this.emit(AdEvents.AD_STARTED, event);
        break;
      case (google as any).ima.dai.api.StreamEvent.Type.COMPLETE:
        this.emit(AdEvents.COMPLETE, event);
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.ERROR:
        {
          const message = '';
          const error = new Error(
            ErrorCode.FAILED_TO_REQUEST_ADS,
            ErrorCategory.ADS,
            ErrorSeverity.CRITICAL,
            message
          );
          this.emit(AdEvents.ERROR, event);
          this.emit(AdEvents.STREAM_LOAD_ERROR, error);
        }
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED:
        this.emit(AdEvents.AD_BREAK_STARTED, event);
        this.isAdBreak = true;
        this.videoPlayer.controls(false);
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED:
        this.emit(AdEvents.AD_BREAK_ENDED, event);
        this.isAdBreak = false;
        this.videoPlayer.controls(true);
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.CLICK:
        this.emit(AdEvents.CLICK, event);
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.SKIPPED:
        this.emit(AdEvents.SKIPPED, event);
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.PAUSED:
        this.emit(AdEvents.PAUSED, event);
        if (this.isAdBreak) {
          this.videoPlayer.controls(true);
          this.videoPlayer.play();
        }
        break;

      case (google as any).ima.dai.api.StreamEvent.Type.RESUMED:
        this.emit(AdEvents.RESUMED, event);
        if (this.isAdBreak) {
          this.videoPlayer.controls(false);
        }
        break;
      default:
        console.log(event.type);
    }
  }

  //Fired when the ad's current time value changes
  onAdProgress(event: any) {
    this.emit(AdEvents.AD_PROGRESS, event);
    const progressData = event.getStreamData().adProgressData;
    const remainingTime = Math.ceil(
      progressData.duration - progressData.currentTime
    );
    const duration = progressData.duration;
    let currentTime = progressData.currentTime;
    currentTime = currentTime > 0 ? currentTime : 0;
    const totalAds = 0;
    const adPosition = 0;
    this.manager.onAdPlayheadUpdated(
      currentTime,
      remainingTime,
      duration,
      adPosition,
      totalAds
    );
  }

  pause(): void {
    //throw new Error('Method not implemented.');
  }
  resume(): void {
    //throw new Error('Method not implemented.');
  }

  destroy(): void {
    console.log('Destroying');
  }
}
