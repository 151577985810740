import {
  playerEventsConstants,
  castEventsConstants
} from '../constants/playerConstants';
import {
  checkIfTobaccoAdvisory,
  getTotalAstonBands
} from '../utils/playerEventsHelper';
import {
  getActivePartnerSubscriptions,
  getContentOwner,
  getSessionStorage
} from '../utils/Utils';
import {
  isEmailPresentForLoggedIn,
  isMobilePresentForLoggedIn
} from './MixPanelMetaHelper';
export class MixpanelAnalyticsTracker {
  mixpanelMetaData?: any;
  singlePlayDetails?: any;
  watchDuration?: 0;
  playerDefferedEvents: any = [];
  videoPlayer?: any;
  mixpanelAdsData?: any;
  clevertapEventPush?: any;

  MixpanelAnalyticsTracker(singlePlayDetails: any) {
    this.singlePlayDetails = singlePlayDetails;
  }

  setMixpanelVideoPlayer = (videoPlayer: any) => {
    this.videoPlayer = videoPlayer;
  };

  setClevertapEventPushRef = (clevertapEventPush: any) => {
    this.clevertapEventPush = clevertapEventPush;
  };

  setMixpanelMetaData = (mixpanelMetaData: any) => {
    this.mixpanelMetaData = mixpanelMetaData;
    if (
      this.mixpanelMetaData &&
      Object.keys(this.mixpanelMetaData).length == 0
    ) {
      return;
    }
    console.log('this.mixpanelMetaData:::::::', this.mixpanelMetaData);
    if (window && (window as any).mixpanel) {
      (window as any).mixpanel.opt_in_tracking();
    } else {
      this.playerDefferedEvents.push({
        eventName: 'optin',
        mixpanelProperties: {}
      });
    }
  };

  setMixpanelAdMetaData = (mixpanelAdsData: any) => {
    this.mixpanelAdsData = mixpanelAdsData;
    console.log('this.mixpanelAdsData:::::::', this.mixpanelAdsData);
  };

  getMixpanelAnalyticDetails() {
    if (
      this.mixpanelMetaData &&
      this.mixpanelMetaData.analyticData &&
      this.mixpanelMetaData.mixpanelDetail
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSeriesName(singlePlayData: {asset_subtype: string; showDetails: any}) {
    if (
      singlePlayData.asset_subtype == 'episode' ||
      singlePlayData.showDetails
    ) {
      return this.mixpanelMetaData.assetName;
    } else {
      return this.mixpanelMetaData.show;
    }
  }

  isSubtileSupport() {
    return window &&
      (window as any).videoPlayer &&
      (window as any).videoPlayer.textTracks().length > 0
      ? true
      : false;
  }

  getMixpanelAdsDetails() {
    if (
      this.mixpanelAdsData &&
      typeof this.mixpanelAdsData === 'object' &&
      Object.keys(this.mixpanelAdsData).length
    ) {
      return true;
    } else {
      return false;
    }
  }
  getMixpanelDetails(details: string | undefined) {
    if (details === undefined || details === 'NA' || details === '') {
      return 'N/A';
    } else {
      if (details === 'sports_vod' || details === 'live_event') {
        return 'Sports ILT20';
      }
      return details;
    }
  }

  getBillingType(singlePlayDetails: any) {
    const {entitlement: {isTrailer = 0} = {}, trailerDetails = {}} =
      singlePlayDetails || {};
    const assetDetails = !isTrailer
      ? singlePlayDetails.assetDetails
      : trailerDetails;
    if (
      assetDetails &&
      assetDetails.billing_type &&
      !assetDetails.isBillingTypeSet
    ) {
      return assetDetails.billing_type;
    } else {
      return 'N/A';
    }
  }

  getBusinessType(singlePlayDetails: {
    assetDetails?: any;
    entitlement?: {isTrailer: number};
    trailerDetails?: object;
  }) {
    const {entitlement: {isTrailer = 0} = {}, trailerDetails = {}} =
      singlePlayDetails || {};
    const assetDetails = !isTrailer
      ? singlePlayDetails.assetDetails
      : trailerDetails;
    if (assetDetails && assetDetails.business_type) {
      return assetDetails.business_type;
    } else if (this.mixpanelMetaData.mixpanelDetail.contentType) {
      return this.mixpanelMetaData.mixpanelDetail.contentType;
    } else {
      return 'N/A';
    }
  }

  getCurrentAudioTrack(singlePlayDetails: {
    assetDetails?: {audio_languages?: string[]};
  }) {
    const {assetDetails: {audio_languages = ['N/A']} = {}} =
      singlePlayDetails || {};
    try {
      const currentaudioTrack = this.videoPlayer
        .audioTracks()
        .tracks_.filter((audio: {enabled: boolean}) => audio.enabled === true);
      if (currentaudioTrack.length > 0) {
        return currentaudioTrack[0].label === 'default'
          ? audio_languages[0]
          : currentaudioTrack[0].label;
      } else {
        return audio_languages[0];
      }
    } catch (error) {
      return audio_languages[0];
    }
  }

  getCurrentQuality = () => {
    let selectedQuality = 'Auto';
    try {
      const qualityLevels =
        (window && (window as any).videoPlayer.qualityLevels()) || {};
      if (qualityLevels && qualityLevels.length > 0) {
        selectedQuality =
          qualityLevels[qualityLevels.selectedIndex].height || 'Auto';
      }
      return selectedQuality;
    } catch (error) {
      return selectedQuality;
    }
  };

  isTalamossEnabaled = () => {
    if (this.mixpanelMetaData.mixpanelDetail) {
      const {origin, modelName, clickId} = this.mixpanelMetaData.mixpanelDetail;
      if (
        origin &&
        modelName &&
        clickId &&
        origin !== 'N/A' &&
        modelName !== 'N/A' &&
        clickId !== 'N/A'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  triggerCleverTap = ({
    eventName,
    mixpanelProperties
  }: {
    eventName: any;
    mixpanelProperties: any;
  }) => {
    if (this.clevertapEventPush) {
      this.clevertapEventPush({eventName, mixpanelProperties});
    }
  };

  triggerMixpanelDefferedEvents = () => {
    if (this.playerDefferedEvents && this.playerDefferedEvents.length) {
      this.playerDefferedEvents.forEach(
        (event: {eventName: any; mixpanelProperties: any}) => {
          if (event.eventName === 'optin') {
            (window as any).mixpanel.opt_in_tracking();
          } else {
            this.triggerCleverTap(event);
            (window as any).mixpanel.track(
              event.eventName,
              event.mixpanelProperties
            );
          }
        }
      );
      this.playerDefferedEvents = [];
    }
  };

  queueMixpanelEvents = (
    eventName: any,
    mixpanelProperties: {
      Source: string;
      'Content Name': string;
      'Content ID': string;
      Genre: string;
      Characters: string;
      'Content Duration': string;
      'Publishing Date': string;
      Series: any;
      'Episode No': string;
      'Content Specification': string;
      'Top Category': string;
      'Channel Name': string;
      'DRM Video': any;
      Subtitles: boolean;
      'Content Original Language': string;
      'Audio Language': any;
      'Subtitle Language': string;
      'Tab Name': string;
      'Carousal Name': string;
      'Carousal ID': string;
      'Page Name': any;
      'Content Type': any;
      'Content Billing Type': any;
      'New Quality': string;
      isEduauraa: any;
      'Player Name': string;
      'Player Version': string;
      'Player Head Position'?: string;
      'Vertical Index': any;
      'Horizontal Index': any;
      'Image URL': string;
      'Video Initiation Method'?: string;
      isTalamoos: boolean;
      'Talamoos origin'?: string;
      'Talamoos modelName'?: string;
      'Talamoos clickID'?: string;
      'Preview status'?: string;
      'Advertiser ID'?: string;
      'Ad Title'?: string;
      'Ad Type'?: string;
      'Ad Provider'?: string;
      'Ad Server Type'?: string;
      'Ad Position'?: string;
      'Ad Creative ID'?: string;
      'Ad Location'?: string;
      'Ad Duration'?: string;
      'Ad Cue Time'?: string;
      'Ad Destination URL'?: string;
      'Ad Pod Position'?: string;
      'Midroll Position'?: string;
      isLive?: string | boolean;
    }
  ) => {
    if (window && (window as any).mixpanel) {
      if (this.playerDefferedEvents.length) {
        this.playerDefferedEvents.push({eventName, mixpanelProperties});
        this.triggerMixpanelDefferedEvents();
      } else {
        this.triggerCleverTap({eventName, mixpanelProperties});
        (window as any).mixpanel.track(eventName, mixpanelProperties);
      }
    } else {
      this.playerDefferedEvents.push({eventName, mixpanelProperties});
    }
  };

  videoMixpanelTrack({
    mixpanelMetaData,
    eventName,
    watchDuration,
    playerDetails
  }: any) {
    const {
      singlePlayData: {
        assetDetails: {content_partner_details: partnerDetails = {}} = {}
      } = {}
    } = mixpanelMetaData || {};
    const {
      content_partner_name: partnerName = '',
      content_partner_id: partnerId = ''
    } = partnerDetails;
    const activePartnerSubscriptions = getActivePartnerSubscriptions();
    const contentOwner = getContentOwner(partnerDetails);
    const aggregatorUserType = activePartnerSubscriptions.includes(
      partnerName.toLowerCase()
    )
      ? 'Subscribed'
      : 'Not Subscribed';
    this.mixpanelMetaData = mixpanelMetaData;
    const convivaID =
      (this.mixpanelMetaData &&
        this.mixpanelMetaData.mixpanelDetail &&
        this.mixpanelMetaData.mixpanelDetail.convivaSessionId) ||
      'NA';
    const convivaSessionID = convivaID.replace(/-/g, '');
    const mixpanelControlEvents = {
      // All E common property..
      Source:
        this.getMixpanelAnalyticDetails() == true
          ? this.getMixpanelDetails(this.mixpanelMetaData.mixpanelDetail.source)
          : 'N/A',
      'Content Name': this.getMixpanelDetails(
        this.mixpanelMetaData.analyticData.original_title
      ),
      'Content ID': this.getMixpanelDetails(this.mixpanelMetaData.contentID),
      Genre: this.getMixpanelDetails(this.mixpanelMetaData.gener),
      Characters: this.getMixpanelDetails(this.mixpanelMetaData.character),
      'Content Duration': this.getMixpanelDetails(
        this.mixpanelMetaData.duration
      ),
      'Publishing Date': this.getMixpanelDetails(this.mixpanelMetaData.pubDate),
      Series: this.getSeriesName(mixpanelMetaData.singlePlayData),
      'Episode No': this.getMixpanelDetails(
        this.mixpanelMetaData.episodeNumber
      ),
      'Content Specification': this.getMixpanelDetails(
        this.mixpanelMetaData.analyticData.asset_subtype
      ),
      'Top Category': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.topCategory
      ),
      'Channel Name': this.getMixpanelDetails(this.mixpanelMetaData.channel),
      'DRM Video': this.mixpanelMetaData.drmVideo,
      Subtitles: this.isSubtileSupport(),
      'Content Original Language': this.getMixpanelDetails(
        this.mixpanelMetaData.originalLanguage
      ),
      'Audio Language': this.getCurrentAudioTrack(
        mixpanelMetaData.singlePlayData
      ),
      'Aggregator Partner Name': partnerName || 'N/A',
      'Aggregator Partner ID': partnerId || 'N/A',
      'Content Owner': contentOwner,
      'Aggregator User Type': aggregatorUserType,
      'Active Partner Subscriptions': activePartnerSubscriptions || 'N/A',
      'Subtitle Language': this.getMixpanelDetails(
        this.mixpanelMetaData.subtitle
      ),
      'Tab Name': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.tabName
      ),
      'Carousal Name': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.carouselName
      ),
      'Carousal ID': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.carousalId
      ),
      'Page Name':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.pageName
          : 'N/A',
      'Content Type': this.getBusinessType(mixpanelMetaData.singlePlayData),
      'Content Billing Type':
        this.getBillingType(this.mixpanelMetaData.singlePlayData) || 'N/A',
      'New Quality': this.getCurrentQuality() || 'Auto',
      isEduauraa:
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.isEduauraa
          : false,
      'Player Name': this.getMixpanelDetails(this.mixpanelMetaData.playerName),
      'Player Version': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.playerVersion
      ),
      'Player Head Position': this.getMixpanelDetails(
        this.mixpanelMetaData.playerHeadPosition
      ),
      'Vertical Index':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.verticalIndex
          : 'N/A',
      'Horizontal Index':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.horizontalIndex
          : 'N/A',
      'Image URL': this.getMixpanelDetails(
        this.mixpanelMetaData.analyticData.image_url
          ? this.mixpanelMetaData.analyticData.image_url
          : 'N/A'
      ),
      'Video Initiation Method': 'N/A',
      isTalamoos: this.isTalamossEnabaled(),
      Is_email_present: isEmailPresentForLoggedIn() || false,
      is_mobile_Present: isMobilePresentForLoggedIn() || false
    };
    if (playerDetails && playerDetails.element) {
      (mixpanelControlEvents as any)['Element'] = playerDetails.element;
    }
    if (playerDetails && playerDetails.widgetName) {
      (mixpanelControlEvents as any)['Widget Name'] = playerDetails.widgetName;
    }
    if (playerDetails && playerDetails.buttonType) {
      (mixpanelControlEvents as any)['Button Type'] = playerDetails.buttonType;
    }
    if (playerDetails && playerDetails.previewStatus) {
      (mixpanelControlEvents as any)['Preview status'] =
        playerDetails.previewStatus;
    }
    if (typeof watchDuration !== 'undefined') {
      this.watchDuration = watchDuration;
      (mixpanelControlEvents as any)['Watch Duration'] = Math.floor(
        this.watchDuration as number
      );
    }
    if (eventName === 'Skip Intro') {
      (mixpanelControlEvents as any)['Player Head start position'] = Math.floor(
        playerDetails.playerHeadStartPosition
      );
      (mixpanelControlEvents as any)['Player Head End Position'] = Math.floor(
        playerDetails.playerHeadEndPosition
      );
    }
    if (eventName === playerEventsConstants.PLAYBACK_ERROR) {
      (mixpanelControlEvents as any)['Error Code'] = playerDetails.errorCode;
      (mixpanelControlEvents as any)['Error Message'] =
        playerDetails.errorMessage;
    }
    if (eventName === 'Banner AutoPlay') {
      (mixpanelControlEvents as any)['Video View'] = 1;
      (mixpanelControlEvents as any)['Video Autoplay'] = 'true';
      (mixpanelControlEvents as any)['Conviva Session ID'] = convivaSessionID;
    }
    if (eventName === 'Video View') {
      // mp metrics start
      const metricsDataObjectFromLS = getSessionStorage('MIXPANEL_METRICS');
      (mixpanelControlEvents as any)['User Click to SPAPI Request'] =
        metricsDataObjectFromLS['SPAPI_Request_Time_1'] -
        metricsDataObjectFromLS['CLICKED_EVT'];
      (mixpanelControlEvents as any)['SPAPI Request to Response'] =
        metricsDataObjectFromLS['SPAPI_Response_Time_1'] -
        metricsDataObjectFromLS['SPAPI_Request_Time_1'];
      // todo below
      (mixpanelControlEvents as any)['Manifest Request to Response'] =
        metricsDataObjectFromLS['Manifest_Response_1'] -
        metricsDataObjectFromLS['Manifest_Request_1'];
      (mixpanelControlEvents as any)['Audio Init Chunk Request to Response'] =
        metricsDataObjectFromLS['Audio_Init_Response_1'] -
        metricsDataObjectFromLS['Audio_Init_Request_1'];
      (mixpanelControlEvents as any)['Video Init Chunk Request to Response'] =
        metricsDataObjectFromLS['Video_Init_Response_1'] -
        metricsDataObjectFromLS['Video_Init_Request_1'];
      (mixpanelControlEvents as any)['Audio Chunk Request to Response'] =
        metricsDataObjectFromLS['Audio_Chunk_Response_1'] -
        metricsDataObjectFromLS['Audio_Chunk_Request_1'];
      (mixpanelControlEvents as any)['Video Chunk Request to Response'] =
        metricsDataObjectFromLS['Video_Chunk_Response_1'] -
        metricsDataObjectFromLS['Video_Chunk_Request_1'];
      (mixpanelControlEvents as any)['DRM License Request to Response'] =
        metricsDataObjectFromLS['DRM_Licence_Response_1'] -
        metricsDataObjectFromLS['DRM_Licence_Request_1'];
      (mixpanelControlEvents as any)[
        'User Click to First Frame Rendered for Video View'
      ] = Date.now() - metricsDataObjectFromLS['CLICKED_EVT'];

      (mixpanelControlEvents as any)[
        'User Click to First Frame Rendered for Video View From Playing Event VideoJS'
      ] =
        metricsDataObjectFromLS['FIRST_FRAME_RENDERED'] -
        metricsDataObjectFromLS['CLICKED_EVT'];

      (mixpanelControlEvents as any)['SPAPI_REQUEST_TIME'] =
        metricsDataObjectFromLS['SPAPI_Request_Time_1'];
      (mixpanelControlEvents as any)['SPAPI_Response_Time'] =
        metricsDataObjectFromLS['SPAPI_Response_Time_1'];
      (mixpanelControlEvents as any)['Manifest_Request'] =
        metricsDataObjectFromLS['Manifest_Request'];
      (mixpanelControlEvents as any)['Manifest_Response'] =
        metricsDataObjectFromLS['Manifest_Response'];
      (mixpanelControlEvents as any)['DRM_Licence_Request'] =
        metricsDataObjectFromLS['DRM_Licence_Request_1'];
      (mixpanelControlEvents as any)['DRM_Licence_Response'] =
        metricsDataObjectFromLS['DRM_Licence_Response_1'];
      (mixpanelControlEvents as any)['Audio_Init_Request'] =
        metricsDataObjectFromLS['Audio_Init_Request'];
      (mixpanelControlEvents as any)['Audio_Init_Response'] =
        metricsDataObjectFromLS['Audio_Init_Response'];
      (mixpanelControlEvents as any)['Audio_Chunk_Request'] =
        metricsDataObjectFromLS['Audio_Chunk_Request'];
      (mixpanelControlEvents as any)['Audio_Chunk_Response'] =
        metricsDataObjectFromLS['Audio_Chunk_Response'];
      (mixpanelControlEvents as any)['Video_Init_Request'] =
        metricsDataObjectFromLS['Video_Init_Request'];
      (mixpanelControlEvents as any)['Video_Init_Response'] =
        metricsDataObjectFromLS['Video_Init_Response'];
      (mixpanelControlEvents as any)['Video_Chunk_Request'] =
        metricsDataObjectFromLS['Video_Chunk_Request'];
      (mixpanelControlEvents as any)['Video_Chunk_Response'] =
        metricsDataObjectFromLS['Video_Chunk_Response'];
      (mixpanelControlEvents as any)['DRM_Licence_Response_FAILED'] =
        metricsDataObjectFromLS['DRM_Licence_Response_FAILED'] || null;

      // mp metrics end
      (mixpanelControlEvents as any)['Video View'] = 1;
      (mixpanelControlEvents as any)['Video Autoplay'] = true;
      (mixpanelControlEvents as any)['Conviva Session ID'] = convivaSessionID;
      (mixpanelControlEvents as any)['Affiliate'] =
        this.mixpanelMetaData.affiliate || 'N/A';
      (mixpanelControlEvents as any)['AutoPlay'] =
        this.mixpanelMetaData.autoPlay;
      (mixpanelControlEvents as any)['Banner/Rail'] =
        this.mixpanelMetaData.mixpanelDetail.bannerOrRail || 'Default';
      sessionStorage.setItem('AutoPlay', 'Default');

      if (partnerDetails && Object.keys(partnerDetails).length) {
        (mixpanelControlEvents as any)['Aggregator Partner Name'] =
          partnerName || 'N/A';
        ((mixpanelControlEvents as any)['Aggregator Partner ID'] =
          partnerId || 'N/A'),
          ((mixpanelControlEvents as any)['Content Owner'] = contentOwner);
        (mixpanelControlEvents as any)['Aggregator User Type'] =
          aggregatorUserType;
        (mixpanelControlEvents as any)['Active Partner Subscriptions'] =
          activePartnerSubscriptions || 'N/A';
      }
    }

    if (eventName === playerEventsConstants.seek_scrub) {
      eventName = 'Scrub/Seek';
      const {
        playerHeadStartPosition,
        playerHeadEndPosition,
        direction,
        seekScrubDuration
      } = playerDetails;
      (mixpanelControlEvents as any)['Player Head start position'] =
        playerHeadStartPosition === 'N/A'
          ? playerHeadStartPosition
          : Math.floor(playerHeadStartPosition);
      (mixpanelControlEvents as any)['Player Head End Position'] =
        playerHeadEndPosition === 'N/A'
          ? playerHeadEndPosition
          : Math.floor(playerHeadEndPosition);
      (mixpanelControlEvents as any)['Direction'] = direction;
      (mixpanelControlEvents as any)['Seek-Scrub Duration'] =
        seekScrubDuration === 'N/A'
          ? seekScrubDuration
          : Math.floor(seekScrubDuration);
    }

    if (eventName === playerEventsConstants.audioLanguageChange) {
      (mixpanelControlEvents as any)['Old Audio Language'] =
        playerDetails.oldAudioLanguage;
      (mixpanelControlEvents as any)['New Audio Language'] =
        playerDetails.newAudioLanguage;
      if (partnerDetails && Object.keys(partnerDetails).length) {
        (mixpanelControlEvents as any)['Aggregator Partner Name'] =
          partnerName || 'N/A';
        (mixpanelControlEvents as any)['Aggregator Partner ID'] =
          partnerId || 'N/A';
        (mixpanelControlEvents as any)['Content Owner'] = contentOwner;
        (mixpanelControlEvents as any)['Aggregator User Type'] =
          aggregatorUserType;
        (mixpanelControlEvents as any)['Active Partner Subscriptions'] =
          activePartnerSubscriptions || 'N/A';
      }
    }
    if (eventName === playerEventsConstants.subTitleChange) {
      (mixpanelControlEvents as any)['Old Subtitle Language'] =
        playerDetails.oldSubtitleLanguage;
      (mixpanelControlEvents as any)['New Subtitle Language'] =
        playerDetails.newSubtitleLanguage;
    }
    if (eventName === playerEventsConstants.qualityChange) {
      (mixpanelControlEvents as any)['Old Quality'] =
        playerDetails.oldVideoQuality;
      (mixpanelControlEvents as any)['New Quality'] =
        playerDetails.newVideoQuality;
    }

    if (eventName === 'Video Exit') {
      (mixpanelControlEvents as any)['Talamoos origin'] =
        this.mixpanelMetaData.mixpanelDetail.origin;
      (mixpanelControlEvents as any)['Talamoos modelName'] =
        this.mixpanelMetaData.mixpanelDetail.modelName;
      (mixpanelControlEvents as any)['Talamoos clickID'] =
        this.mixpanelMetaData.mixpanelDetail.clickId;
    }
    if ((window as any).isSportsLive) {
      (mixpanelControlEvents as any)['isLive'] = true;
    }
    if (
      mixpanelMetaData?.singlePlayData?.assetDetails?.asset_subtype &&
      mixpanelMetaData.singlePlayData.assetDetails.asset_subtype ===
        'sports_vod'
    ) {
      (mixpanelControlEvents as any)['isLive'] = false;
    }
    if (eventName === 'Mute Changed') {
      (mixpanelControlEvents as any)['Mute Value'] = playerDetails.muteValue;
      delete (mixpanelControlEvents as any)['Player Head Position'];
    }
    if (eventName === 'Video View') {
      let containsHealthspot = false;
      if (
        this.mixpanelMetaData.singlePlayData?.tobaccoAdvisoryDetails
          ?.video_segments ||
        this.mixpanelMetaData.singlePlayData?.tobaccoAdvisoryDetails
          ?.aston_segments
      ) {
        containsHealthspot = true;
        if (
          this.mixpanelMetaData.singlePlayData?.tobaccoAdvisoryDetails
            ?.aston_segments
        ) {
          (mixpanelControlEvents as any)['Total Aston Bands'] =
            getTotalAstonBands(this.mixpanelMetaData.singlePlayData);
        }
      }
      (mixpanelControlEvents as any)['Contains HealthSpot'] =
        containsHealthspot;
    }
    if (eventName === 'Healthspot Viewed') {
      (mixpanelControlEvents as any)['Healthspot Placement'] =
        playerDetails.healthSpotPlacement;
      if (playerDetails.advisoryVideoUrl) {
        (mixpanelControlEvents as any)['Advisory URL'] =
          playerDetails.advisoryVideoUrl;
      }
    }
    this.queueMixpanelEvents(eventName, mixpanelControlEvents);
  }

  adsMixpanelTrack({
    mixpanelMetaData,
    mixpanelAdsData,
    eventName,
    playerDetails
  }: any) {
    this.mixpanelMetaData = mixpanelMetaData;
    this.mixpanelAdsData = mixpanelAdsData;
    const mixpanelControlEvents = {
      // All E common property..
      Source:
        this.getMixpanelAnalyticDetails() == true
          ? this.getMixpanelDetails(this.mixpanelMetaData.mixpanelDetail.source)
          : 'N/A',
      'Content Name': this.getMixpanelDetails(
        this.mixpanelMetaData.analyticData.original_title
      ),
      'Content ID': this.getMixpanelDetails(this.mixpanelMetaData.contentID),
      Genre: this.getMixpanelDetails(this.mixpanelMetaData.gener),
      Characters: this.getMixpanelDetails(this.mixpanelMetaData.character),
      'Content Duration': this.getMixpanelDetails(
        this.mixpanelMetaData.duration
      ),
      'Publishing Date': this.getMixpanelDetails(this.mixpanelMetaData.pubDate),
      Series: this.getSeriesName(mixpanelMetaData.singlePlayData),
      'Episode No': this.getMixpanelDetails(
        this.mixpanelMetaData.episodeNumber
      ),
      'Content Specification': this.getMixpanelDetails('NA'),
      'Top Category': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.topCategory
      ),
      'Channel Name': this.getMixpanelDetails(this.mixpanelMetaData.channel),
      'DRM Video': this.mixpanelMetaData.drmVideo,
      Subtitles: this.isSubtileSupport(),
      'Content Original Language': this.getMixpanelDetails(
        this.mixpanelMetaData.originalLanguage
      ),
      'Audio Language': this.getCurrentAudioTrack(
        mixpanelMetaData.singlePlayData
      ),
      'Subtitle Language': this.getMixpanelDetails(
        this.mixpanelMetaData.subtitle
      ),
      'Tab Name': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.tabName
      ),
      'Carousal Name': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.carouselName
      ),
      'Carousal ID': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.carousalId
      ),
      'Page Name':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.pageName
          : 'N/A',
      'Content Type': this.getBusinessType(mixpanelMetaData.singlePlayData),
      'Content Billing Type': this.getBillingType(
        this.mixpanelMetaData.singlePlayData
      ),
      'New Quality': this.getCurrentQuality() || 'Auto',
      isEduauraa:
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.isEduauraa
          : false,
      'Player Name': this.getMixpanelDetails(this.mixpanelMetaData.playerName),
      'Player Version': this.getMixpanelDetails(
        this.mixpanelMetaData.playerVersion
      ),
      'Vertical Index':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.verticalIndex
          : 'N/A',
      'Horizontal Index':
        this.getMixpanelAnalyticDetails() == true
          ? this.mixpanelMetaData.mixpanelDetail.horizontalIndex
          : 'N/A',
      'Image URL': this.getMixpanelDetails(
        this.mixpanelMetaData.analyticData.image_url
          ? this.mixpanelMetaData.analyticData.image_url
          : 'N/A'
      ),
      'Talamoos origin': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.origin
      ),
      'Talamoos modelName': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.modelName
      ),
      'Talamoos clickID': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.clickId
      ),
      isTalamoos: this.isTalamossEnabaled(),
      'Preview status': this.getMixpanelDetails(
        this.mixpanelMetaData.mixpanelDetail.previewStatus
      ),
      'Advertiser ID':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.id)
          : 'N/A',
      'Ad Title':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.title)
          : 'N/A',
      'Ad Type':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.adType)
          : 'N/A',
      'Ad Provider':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.managerName)
          : 'N/A',
      'Ad Server Type':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.managerName)
          : 'N/A',
      'Ad Position':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.type)
          : 'N/A',
      'Ad Creative ID':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.creativeId)
          : 'N/A',
      'Ad Location': 'instream',
      'Ad Duration':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.duration)
          : 'N/A',
      'Ad Cue Time':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.cueTime)
          : 'N/A',
      'Ad Destination URL':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.url)
          : 'N/A',
      'Ad Pod Position':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.podPosition)
          : 'N/A',
      'Midroll Position':
        this.getMixpanelAdsDetails() == true
          ? this.getMixpanelDetails(this.mixpanelAdsData.midRollPosition)
          : 'N/A',
      isLive:
        (window as any).isLive || (window as any).isSportsLive ? true : false,
      Is_email_present: isEmailPresentForLoggedIn() || false,
      is_mobile_Present: isMobilePresentForLoggedIn() || false
    };
    if (eventName === 'Ad View') {
      (mixpanelControlEvents as any)['Video View'] = 0;
      (mixpanelControlEvents as any)['Talamoos origin'] =
        this.mixpanelMetaData.mixpanelDetail.origin;
      (mixpanelControlEvents as any)['Talamoos modelName'] =
        this.mixpanelMetaData.mixpanelDetail.modelName;
      (mixpanelControlEvents as any)['Talamoos clickID'] =
        this.mixpanelMetaData.mixpanelDetail.clickId;
      (mixpanelControlEvents as any)['Preview status'] =
        this.mixpanelMetaData.mixpanelDetail.previewStatus;
      (mixpanelControlEvents as any)['Ad Start Time'] =
        this.getMixpanelAdsDetails() == true
          ? this.mixpanelAdsData.adStartTime &&
            this.mixpanelAdsData.adStartTime < 1
            ? this.mixpanelAdsData.adStartTime
            : Math.floor(this.mixpanelAdsData.adStartTime)
          : 'N/A';
      (mixpanelControlEvents as any)['Banner/Rail'] =
        this.mixpanelMetaData.mixpanelDetail.bannerOrRail || 'Default';
    }
    if (eventName === 'Ad Skip') {
      (mixpanelControlEvents as any)['Element'] = playerDetails.element;
      (mixpanelControlEvents as any)['Button Type'] = playerDetails.buttonType;
    }
    if (eventName === 'Ad Failure') {
      (mixpanelControlEvents as any)['Failure Reason'] =
        playerDetails.failureReason;
    }
    if (eventName === 'Ad Clicks') {
      (mixpanelControlEvents as any)['Player Head Position'] =
        playerDetails.playerHead;
    }

    if (
      eventName === 'Ad Initialized' ||
      eventName === 'Ad View' ||
      eventName === 'Ad Watch Duration' ||
      eventName === 'Ad click' ||
      eventName === 'End Card Impression'
    ) {
      mixpanelControlEvents['Vertical Index'] =
        this.getMixpanelAnalyticDetails() == true &&
        this.mixpanelMetaData.mixpanelDetail.verticalIndex
          ? this.mixpanelMetaData.mixpanelDetail.verticalIndex
          : 'N/A';
      mixpanelControlEvents['Horizontal Index'] =
        this.getMixpanelAnalyticDetails() == true &&
        this.mixpanelMetaData.mixpanelDetail.horizontalIndex
          ? this.mixpanelMetaData.mixpanelDetail.horizontalIndex
          : 'N/A';
    }
    this.queueMixpanelEvents(eventName, mixpanelControlEvents);
  }

  public ReportEvent(eventName: string, eventPlayload: any): any {
    let playerDetails: any = {};
    if (
      !this.mixpanelMetaData ||
      (this.mixpanelMetaData && Object.keys(this.mixpanelMetaData).length == 0)
    ) {
      return;
    }
    switch (eventName) {
      //The first frame of a ads is loaded
      case playerEventsConstants.adInitialized:
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: this.mixpanelAdsData,
            eventName,
            watchDuration: this.watchDuration
          });
        }
        break;
      case playerEventsConstants.adStarted:
        eventName = playerEventsConstants.adView;
        this.mixpanelAdsData.adStartTime = eventPlayload.adStartTime;
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: this.mixpanelAdsData,
            eventName,
            watchDuration: this.watchDuration
          });
        }
        break;

      case playerEventsConstants.adClicked:
        eventName = 'Ad Click';
        playerDetails = {
          playerHead: eventPlayload.adTimer
        };
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: this.mixpanelAdsData,
            eventName,
            watchDuration: this.watchDuration
          });
        }
        break;
      case playerEventsConstants.userClosedAd:
        this.watchDuration = eventPlayload;
        eventName = 'Ad Forced Exit';
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: this.mixpanelAdsData,
            eventName,
            watchDuration: this.watchDuration
          });
        }
        break;
      case playerEventsConstants.adErrors:
        eventName = 'Ad Failure';
        playerDetails = {
          failureReason: eventPlayload
        };
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: {},
            eventName,
            watchDuration: this.watchDuration,
            playerDetails
          });
        }
        break;
      case playerEventsConstants.exitBeforeAdStart:
        eventName = 'Exit Before Ad Start';
        this.adsMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          mixpanelAdsData: this.mixpanelAdsData,
          eventName,
          watchDuration: this.watchDuration
        });
        break;

      case playerEventsConstants.adSkipped:
        this.watchDuration = eventPlayload;
        playerDetails = {
          element: 'Ad Skip',
          buttonType: 'Player'
        };
        eventName = 'Ad Skip';
        if (this.mixpanelAdsData) {
          this.adsMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            mixpanelAdsData: this.mixpanelAdsData,
            watchDuration: this.watchDuration,
            eventName,
            playerDetails
          });
        }
        break;

      // When video ends, watch duration is calculated and capture..
      case playerEventsConstants.adCompleted:
      case playerEventsConstants.playbackEnded:
        //  this.watchDuration =eventPlayload.timeStamp;
        if (eventName == playerEventsConstants.playbackEnded) {
          this.watchDuration = eventPlayload || 0;
          playerDetails = {
            previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
          };
          eventName = 'Video Watch Duration';
          this.videoMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            eventName,
            watchDuration: this.watchDuration,
            playerDetails
          });
          eventName = 'Video Exit';
          this.videoMixpanelTrack({
            mixpanelMetaData: this.mixpanelMetaData,
            eventName,
            watchDuration: this.watchDuration,
            playerDetails
          });
        } else {
          this.watchDuration = eventPlayload.watchDuration;
          eventName = 'Ad Watch Duration';
          if (this.mixpanelAdsData) {
            if (eventPlayload.type && eventPlayload.type === 'EndCard') {
              this.watchDuration = eventPlayload.endcardDuration;
              this.adsMixpanelTrack({
                mixpanelMetaData: this.mixpanelMetaData,
                mixpanelAdsData: {},
                eventName,
                watchDuration: this.watchDuration
              });
            } else {
              this.watchDuration = eventPlayload.watchDuration;
              this.adsMixpanelTrack({
                mixpanelMetaData: this.mixpanelMetaData,
                mixpanelAdsData: this.mixpanelAdsData,
                eventName,
                watchDuration: this.watchDuration
              });
            }
          }
        }
        break;

      //The first frame of a video is loaded
      case playerEventsConstants.firstPlay:
        eventName = 'Video View';
        this.mixpanelMetaData.autoPlay = eventPlayload.autoPlay;
        // read data from data storage
        // spapi req/res
        // license req / res
        // chunk req/res
        // error
        // this.mixpanelMetaData.metrics
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration
        });
        break;
      case playerEventsConstants.PLAYBACK_ERROR:
        playerDetails = {
          errorCode: eventPlayload.errorCode,
          errorMessage: eventPlayload.errorMessage
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.autoSeek:
        eventName = 'Auto-seek';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.onReplay:
        playerDetails = {
          element: 'Replay',
          buttonType: 'Player',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Replay';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.skipIntro:
        playerDetails = {
          element: 'Skip Intro',
          buttonType: 'Player',
          playerHeadStartPosition: eventPlayload.introStartTime,
          playerHeadEndPosition: eventPlayload.introEndTime,
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.watchCredits:
        playerDetails = {
          element: 'Watch Credits',
          buttonType: 'Player',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.pauseEvent:
        playerDetails = {
          element: 'Pause',
          buttonType: 'Player',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Pause';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.playEvent:
        playerDetails = {
          element: 'Resume',
          buttonType: 'Player',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = playerEventsConstants.resume;
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.onReplay:
        playerDetails = {
          element: 'Replay',
          buttonType: 'Player',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Replay';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.seek_scrub:
        const {from, to} = eventPlayload;
        playerDetails = {
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus,
          playerHeadStartPosition: from,
          playerHeadEndPosition: to,
          direction: from < to ? 'forward' : 'backward',
          seekScrubDuration:
            from < to
              ? Math.floor(to) - Math.floor(from)
              : Math.floor(from) - Math.floor(to)
        };
        if (from === 'N/A' || from === 'N/A') {
          playerDetails.direction = 'N/A';
          playerDetails.seekScrubDuration = 'N/A';
        }
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.audioLanguageChange:
        playerDetails = {
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus,
          oldAudioLanguage: eventPlayload.oldAudioLanguage,
          newAudioLanguage: eventPlayload.newAudioLanguage
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.subTitleChange:
        playerDetails = {
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus,
          oldSubtitleLanguage: eventPlayload.oldSubtitleLanguage,
          newSubtitleLanguage: eventPlayload.newSubtitleLanguage
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.qualityChange:
        playerDetails = {
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus,
          oldVideoQuality: eventPlayload.oldVideoQuality,
          newVideoQuality: eventPlayload.newVideoQuality
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.muteChanged:
        playerDetails = {
          element: 'Mute',
          buttonType: 'Player',
          muteValue: eventPlayload.muteValue ? 'on' : 'off'
        };
        eventName = 'Mute Changed';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.ribbin_ctas:
      case playerEventsConstants.playerCtas:
      case playerEventsConstants.ribbonImpression:
        playerDetails = {
          element: 'Settings',
          buttonType: 'Player'
        };
        if (eventName === 'Ribbon CTAs' || eventName === 'Ribbon Impression') {
          playerDetails.buttonType = 'Ribbon';
        }
        if (eventPlayload && eventPlayload.element) {
          playerDetails.element = eventPlayload.element;
        }
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case castEventsConstants.CAST_STARTED:
      case castEventsConstants.CAST_ENDED:
      case castEventsConstants.CAST_FAILED:
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.PLAYER_VIEW_CHANGED:
        playerDetails = {
          element: 'Fullscreen Toggle',
          buttonType: 'Player'
        };
        eventName = playerEventsConstants.PLAYER_VIEW_CHANGED;
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.healthSpotViewed:
        eventName = playerEventsConstants.healthSpotViewed;
        playerDetails = {
          healthSpotPlacement: eventPlayload.healthSpotPlacement
        };
        if (eventPlayload.advisoryVideoUrl) {
          playerDetails.advisoryVideoUrl = eventPlayload.advisoryVideoUrl;
        }
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
      case playerEventsConstants.goLiveEvent:
        playerDetails = {
          element: 'Go Live',
          buttonType: 'Player',
          Content_Specification: 'Sports ILT20',
          Page_Name: 'ILT20 ConsumptionPage',
          isLive: 'True',
          Top_Category: 'Sports ILT20'
        };
        eventName = 'Player CTA';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.PIN_SCOREBOARD:
        playerDetails = {
          widgetName: 'Pin Scoreboard',
          buttonType: 'View',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Widget Impression';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.SCORE_CLICK:
        playerDetails = {
          widgetName: 'Pin Scoreboard',
          element: 'Score Click',
          buttonType: 'Click',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Widget CTAs';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.PIN:
        playerDetails = {
          widgetName: 'Pin Scoreboard',
          element: 'Pin',
          buttonType: 'Click',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Widget CTAs';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.UNPIN:
        playerDetails = {
          widgetName: 'Pin Scoreboard',
          element: 'Unpin',
          buttonType: 'Click',
          previewStatus: this.mixpanelMetaData.mixpanelDetail.previewStatus
        };
        eventName = 'Widget CTAs';
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      case playerEventsConstants.ILT_ERROR_FALLBACK:
        eventName = playerEventsConstants.ILT_ERROR_FALLBACK;
        playerDetails = {
          error: 'DAI STream Failure'
        };
        this.videoMixpanelTrack({
          mixpanelMetaData: this.mixpanelMetaData,
          eventName,
          watchDuration: this.watchDuration,
          playerDetails
        });
        break;
      default:
        break;
    }
  }
}
