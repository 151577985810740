import {
  getPosterUrl,
  getChromecastCustomData
} from '../utils/playerEventsHelper';

export class CastHelper {
  videoPlayer?: any;
  castPlayer?: cast.framework.RemotePlayer;
  remotePlayerController?: cast.framework.RemotePlayerController;

  constructor(
    videoPlayer: any,
    remotePlayer: cast.framework.RemotePlayer,
    remotePlayerController: cast.framework.RemotePlayerController
  ) {
    this.videoPlayer = videoPlayer;
    this.castPlayer = remotePlayer;
    this.remotePlayerController = remotePlayerController;
  }

  getChromecastCustomData(videoPlayer: any) {
    return getChromecastCustomData(videoPlayer);
  }

  enableCastMode() {
    (this.videoPlayer as any).pause();
    this.removePlayerControls();
    this.displayPosterImage();
    this.disableTextTracks();
    const castControls = document.querySelector('.cast-player-seek-pause');
    if (!castControls) {
      this.createCastControls();
    }
    if (!(window as any)['isCastConnected']) {
      this.addCastEventListeners();
    }
    if ((window as any)['isCastConnected']) {
      this.removeEventListeners();
      this.addCastEventListeners();
    }
    (window as any)['isCastConnected'] = true;
  }

  displayPosterImage() {
    const posterUrl = getPosterUrl();
    (this.videoPlayer as any | undefined)?.poster(posterUrl);
    (this.videoPlayer as any | undefined)?.posterImage?.show();
    (
      document.querySelector(
        '.playerContainer .playerComp .chromeCastBtnContainer'
      ) as HTMLElement
    ).style.marginTop = '10px';
  }

  removePlayerControls() {
    const playerControls =
      document.querySelector<HTMLElement>('.vjs-skip-wrapper');
    if (playerControls) {
      playerControls.style.display = 'none';
    }
  }

  disableTextTracks() {
    const closedCaptions = document.querySelector<HTMLElement>(
      '.vjs-text-track-display'
    );
    if (closedCaptions) {
      closedCaptions.style.display = 'none';
    }
  }

  enableTextTracks() {
    const closedCaptions = document.querySelector<HTMLElement>(
      '.vjs-text-track-display'
    );
    if (closedCaptions) {
      closedCaptions.style.display = 'block';
    }
  }

  createCastControls() {
    const videoEL = this.videoPlayer && this.videoPlayer.el();
    const castSessionSuccessWrapper = document.createElement('div');
    castSessionSuccessWrapper.className = 'cast-player-seek-pause';
    castSessionSuccessWrapper.setAttribute('style', 'display:flex;');

    const seekForwardBtn = document.createElement('button');
    seekForwardBtn.className = 'cast-forward-seek';
    seekForwardBtn.setAttribute('style', 'margin:auto');
    seekForwardBtn.onclick = () => {
      this.seekCastReceiver(10);
    };
    const seekForIcon = document.createElement('i');
    seekForIcon.className = 'material-icons';
    seekForIcon.innerText = 'forward_10';
    seekForIcon.setAttribute(
      'style',
      'line-height:30px; font-size:30px; color:#fff; cursor:pointer;'
    );
    seekForwardBtn.appendChild(seekForIcon);

    const seekBackBtn = document.createElement('button');
    seekBackBtn.className = 'cast-backward-seek';
    seekBackBtn.setAttribute('style', 'margin:auto');
    seekBackBtn.onclick = () => {
      this.seekCastReceiver(-10);
    };

    const seekBackIcon = document.createElement('i');
    seekBackIcon.className = 'material-icons';
    seekBackIcon.innerText = 'replay_10';
    seekBackIcon.setAttribute(
      'style',
      'line-height:30px; font-size:30px; color:#fff; cursor:pointer;'
    );
    seekBackBtn.appendChild(seekBackIcon);

    const playPauseBtn = document.createElement('button');
    playPauseBtn.setAttribute('style', 'margin:auto');
    playPauseBtn.onclick = () => {
      (
        this.remotePlayerController as cast.framework.RemotePlayerController
      ).playOrPause();
      this.togglePlayPauseIcon();
    };

    const playPauseIcon = document.createElement('i');
    playPauseIcon.className = 'material-icons cast-play-pause';
    playPauseIcon.innerText = 'paused';
    playPauseIcon.setAttribute(
      'style',
      'line-height:30px; font-size:30px; color:#fff; cursor:pointer;'
    );
    playPauseBtn.appendChild(playPauseIcon);

    castSessionSuccessWrapper &&
      castSessionSuccessWrapper.appendChild(seekBackBtn);
    castSessionSuccessWrapper &&
      castSessionSuccessWrapper.appendChild(playPauseBtn);
    castSessionSuccessWrapper &&
      castSessionSuccessWrapper.appendChild(seekForwardBtn);
    videoEL && videoEL.appendChild(castSessionSuccessWrapper);
  }

  seekCastReceiver(time: number) {
    (this.castPlayer as cast.framework.RemotePlayer).currentTime =
      Math.floor(
        Math.abs((this.castPlayer as cast.framework.RemotePlayer).currentTime)
      ) + time;
    (
      this.remotePlayerController as cast.framework.RemotePlayerController
    ).seek();
  }

  addCastEventListeners() {
    const castRemotePlayerEvent = cast.framework.RemotePlayerEventType;
    (
      this.remotePlayerController as cast.framework.RemotePlayerController
    ).addEventListener(castRemotePlayerEvent.ANY_CHANGE, (event) =>
      this.castEventChange(event)
    );
  }

  castLoadMediaFailed() {
    if ((this.videoPlayer as any | undefined)?.paused) {
      (this.videoPlayer as any | undefined)?.play();
    }
  }

  castEventChange(event: any) {
    let eventName: any;
    if (event) {
      eventName = event.field === 'playerState' ? event.value : event.field;
      eventName = eventName && eventName.replace(/\s/g, '').toLowerCase();
      switch (eventName) {
        case 'paused':
        case 'playing':
          // updateDisplayText();
          this.togglePlayPauseIcon();
          break;
        case 'buffering':
          // updateDisplayText();
          break;
        case 'ismuted':
          (this.videoPlayer as any).muted(
            (this.castPlayer as cast.framework.RemotePlayer).isMuted
          );
          break;
        case 'volumelevel':
          (this.videoPlayer as any | undefined)?.volume(Number(event.value));
          break;
        case 'currenttime':
          if (!(this.videoPlayer as any | undefined)?.paused()) {
            (this.videoPlayer as any | undefined)?.pause();
          }
          if (event.value > 0) {
            // .vjs-has-started display none override done to display poster
            (
              document.querySelector(
                '.vjs-has-started .vjs-poster'
              ) as HTMLElement
            ).style.display = 'block';
            if (!(window as any)['seekingSenderBar']) {
              (this.videoPlayer as any | undefined)?.currentTime(event.value);
            }
          }
          break;
        default:
          break;
      }
    }
  }

  removeEventListeners() {
    const castRemotePlayerEvent = cast.framework.RemotePlayerEventType;
    (
      this.remotePlayerController as
        | cast.framework.RemotePlayerController
        | undefined
    )?.removeEventListener(castRemotePlayerEvent.ANY_CHANGE, (event) =>
      this.castEventChange(event)
    );
  }

  stopCasting() {
    this.removeEventListeners();
    // override rule turned to default
    (
      document.querySelector('.vjs-has-started .vjs-poster') as HTMLElement
    ).style.display = 'none';
    (
      document.querySelector(
        '.playerContainer .playerComp .chromeCastBtnContainer'
      ) as HTMLElement
    ).style.marginTop = '-5px';
    const castReceiverControls = document.querySelector(
      '.cast-player-seek-pause'
    );
    this.enableTextTracks();
    const videoEL = this.videoPlayer && this.videoPlayer.el();
    if (castReceiverControls) {
      videoEL.removeChild(castReceiverControls);
    }
    const playerControls =
      document.querySelector<HTMLElement>('.vjs-skip-wrapper');
    if (playerControls) {
      playerControls.style.display = 'block';
    }
    this.videoPlayer &&
      this.videoPlayer.player &&
      this.videoPlayer.player() &&
      this.videoPlayer.play();
    this.videoPlayer &&
      this.videoPlayer.player &&
      this.videoPlayer.player() &&
      this.videoPlayer.poster('');
    this.videoPlayer &&
      this.videoPlayer.player &&
      this.videoPlayer.player() &&
      this.videoPlayer.posterImage.hide();
  }

  togglePlayPauseIcon() {
    const icon = document.querySelector('.cast-play-pause');
    if (icon) {
      if (
        (this.castPlayer as cast.framework.RemotePlayer | undefined)?.isPaused
      ) {
        icon.innerHTML = 'play_arrow';
      } else {
        icon.innerHTML = 'paused';
      }
    }
  }
}

export function seekReceiverWithSeekedSender(videoPlayer: any) {
  const castPlayer = new cast.framework.RemotePlayer();
  const remotePlayerController = new cast.framework.RemotePlayerController(
    castPlayer
  );
  castPlayer.currentTime = Math.abs(videoPlayer.currentTime());
  remotePlayerController.seek();
  (window as any)['seekingSenderBar'] = false;
}

export function setReceiverVolume(videoPlayer: any) {
  const castPlayer = new cast.framework.RemotePlayer();
  const remotePlayerController = new cast.framework.RemotePlayerController(
    castPlayer
  );
  castPlayer.volumeLevel = videoPlayer.volume();
  remotePlayerController.setVolumeLevel();
}

export function textTracksChange(label = '', videoPlayer: any) {
  const castSender = videoPlayer.videoPlayer.getCastSender();
  const castSession = castSender.castSession;
  const castPlayerTracks =
    castSender.remotePlayer &&
    castSender.remotePlayer.mediaInfo &&
    castSender.remotePlayer.mediaInfo.tracks;
  const subtitleLabel = label ? label.toLowerCase() : 'off';
  const tracks = [];
  if (subtitleLabel !== 'off') {
    const trackData = castPlayerTracks.find(
      ({
        language = '',
        type = '',
        trackId
      }: {
        language: string;
        type: string;
        trackId: any;
      }) =>
        language.toLowerCase().includes(`${subtitleLabel}`) &&
        type.toLowerCase().includes('text') &&
        trackId
    );
    trackData && trackData.trackId && tracks.push(trackData.trackId);
  }
  const media = castSession.getMediaSession();
  const tracksInfoRequest = new chrome.cast.media.EditTracksInfoRequest(tracks);
  media.editTracksInfo(
    tracksInfoRequest,
    () => console.log('Text track changed'),
    () => console.log('Failed to change text track')
  );
}
